import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'date-time-format-timezone';
import React from 'react';
import { render }from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {TokenProvider} from '_services';
import {theme} from '_styles'
import {store} from '_redux';
import * as serviceWorker from './serviceWorker';
import App from './App';

const rootElement = document.getElementById('root')

render(
  <Provider store={store}>
    <TokenProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </TokenProvider>
  </Provider>,
  rootElement
);

serviceWorker.unregister();

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const env = process.env.NODE_ENV;

const baseURL= (env === 'production') ? 'https://linknbuild.com/' : 'http://localhost:3000/'

export const MainAppApi = createApi({
  reducerPath: 'MainAppApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: baseURL ,
    prepareHeaders: (headers, { getState }) => {
      const token = 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo1fQ.Ukp0BJ8XxFns9JTxqoiCrH3encXeuEKyraPkf5D5EXw' //(getState()).auth.profile.token
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => 'api/crew_manager/v1/profile',
    }),
  }),
})

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const env = process.env.NODE_ENV;

const baseURL= (env === 'production') ? 'https://lnb.ai/api/v1' : 'http://localhost:3001'

export const ReportsApi = createApi({
  reducerPath: 'ReportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState()).auth.profile.token
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getApiHealth: builder.query({
      query: () => 'health',
    }),
    getCompany: builder.query({
      query: (companyId) => `companies/${companyId}`
    }),
    getEmployeeAttendanceDetails: builder.query({
      query: ({companyId, employeeProfileDataId, startDate}) => {
        return {
          url: `companies/${companyId}/employees/${employeeProfileDataId}/attendance_details`,
          params: {start_date: startDate},
        }
      }
    }),
    getEmployeeTimes: builder.query({
      query: ({
        companyId,
        companyNameText,
        endDate,
        projectIds,
        startDate,
      }) => {
        return {
          url: `reports/employees/employee_times`,
          params: {
            company_id: companyId,
            company_name_search: companyNameText,
            end_date: endDate,
            project_ids: projectIds,
            start_date: startDate,
          }
        }
      },
    }),
    getExcelDownload: builder.query({
      query: () => 'excel_download',
    }),
    getLivePlantDashboard: builder.query({
      query: ({projectId}) => {
        return {
          url: `/projects/${projectId}/live_plant_dashboard`,
        }
      }
    }),
    getPlanComments: builder.query({
      query: ({projectIds, startDate, endDate}) => {
        return {
          url: `plan_comments`,
          params: {project_ids: projectIds, start_date: startDate, end_date: endDate},
        }
      }
    }),
    getPlantDeliveries: builder.query({
      query: ({companyId, startDate, endDate}) => {
        return {
          url: `companies/${companyId}/project_plant_deliveries`,
          params: {start_date: startDate, end_date: endDate},
        }
      }
    }),
    getPlantActivityAnalysis: builder.query({
      query: ({projectId, startDate, endDate}) => {
        return {
          url: `projects/${projectId}/plant_activity_analysis`,
          params: {start_date: startDate, end_date: endDate},
        }
      }
    }),
    getProjectCompany: builder.query({
      query: (projectId) => `projects/${projectId}/company`
    }),
    getProjectCompletionRequirements: builder.query({
      query: (projectId) => `projects/${projectId}/completion_requirements`,
    }),
    getProjectDashboard: builder.query({
      query: ({companyId, projectId, startDate}) => {
        return {
          url: `reports/companies/${companyId}/projects/${projectId}`,
          params: {start_date: startDate},
        }
      }
    }),
    getFirstLastPlantAnalysis: builder.query({
      query: ({projectId, startDate, endDate}) => {
        return {
          url: `projects/${projectId}/plant_first_last_analysis`,
          params: {start_date: startDate, end_date: endDate},
        }
      }
    }),
    getProjectDelayReasons: builder.query({
      query: (projectId) => `projects/${projectId}/delay_reasons`,
    }),
    getProjectMaterials: builder.query({
      query: (projectId) => `projects/${projectId}/materials`,
    }),
    getProjectLocations: builder.query({
      query: (projectId) => `projects/${projectId}/locations`,
    }),
    getProjects: builder.query({
      query: ({companyId, startDate, endDate}) => {
        return {
          url: `companies/${companyId}/projects`,
          params: {start_date: startDate, end_date: endDate},
        }
      }
    }),
    getSimSummary: builder.query({
      query: (projectIds) => {
        return {
          url: `sim_cards`,
          params: {project_ids: projectIds},
        }
      }
    }),
    postLogin: builder.mutation({
      query: (body) => ({
        url: 'login',
        method: 'POST',
        body: body,
      }),
    }),
  }),
})

export const { 
  useGetCompanyQuery,
  useGetEmployeeTimesQuery,
  useGetLivePlantDashboardQuery,
  useGetPlantActivityAnalysisQuery,
  useGetFirstLastPlantAnalysisQuery,
  useGetProjectDashboardQuery,
  useGetProjectDelayReasonsQuery,
  useGetProjectLocationsQuery,
  useGetProjectMaterialsQuery,
  useGetProjectsQuery,
  usePostLoginMutation,
} = ReportsApi

import {APICall} from '_services'

const downloadFile = async (endpoint, filename, params) => {
  APICall.get(endpoint, {params, responseType: 'blob'})
    .then(response => {
      let url = window.URL.createObjectURL(response.data);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename
      a.click();
    });
}

export default downloadFile

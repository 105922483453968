import React, {useState} from 'react';

import {Button, Slider, Box, Grid } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import {format, addMonths, eachMonthOfInterval, startOfMonth} from 'date-fns'


const DateSlider = (props) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    maxDate,
  } = props

  const [minDate, setMinDate] = useState(startOfMonth(addMonths(startDate, -1)))
  const [sliderStartDate, setSliderStartDate] = useState(startDate)
  const [sliderEndDate, setSliderEndDate] = useState(endDate)

  const onPressedSetMinEarlier = () => setMinDate(addMonths(minDate,-1))

  const handleSliderChange = (e, newVal) => {
    setSliderStartDate(new Date(newVal[0]))
    setSliderEndDate(new Date(newVal[1]))
  }

  const onPressedGo = () => {
    setStartDate(sliderStartDate)
    setEndDate(sliderEndDate)
  }

  const sliderMarks = () => {
    const buildMark = (d) => ({value: d.valueOf(), label: format(d, 'MMM')})
    const marks = eachMonthOfInterval({start: minDate, end: maxDate}).map((d) => buildMark(d))
    return marks
  }

  return (
    <Box width = {300} style={{padding: 40, display: 'inline-block'}}>
      <Grid container spacing={0}>
        <Grid item xs={1}>
          <Button onClick={onPressedSetMinEarlier} style={{margin: 0, marginLeft: '-15px', marginTop: '2px', padding: 0, minWidth: 0}} fullWidth><ChevronLeftIcon /></Button>
        </Grid>
        <Grid item xs={10}>
          <Slider
            marks={sliderMarks()}
            size='small'
            value={[sliderStartDate.valueOf(), sliderEndDate.valueOf()]}
            onChange={handleSliderChange}
            valueLabelDisplay='on'
            valueLabelFormat={(val) => format(new Date(val), 'dd MMM')}
            max={maxDate.valueOf()}
            min={minDate.valueOf()}
            step={86400000}
          />
        </Grid>
        <Grid item xs={1}>
          <Button  variant='contained' onClick={onPressedGo} style={{margin: '0', marginLeft: '15px', marginTop: '2px', padding: 0, minWidth: '30px'}}>Go</Button>
        </Grid>
      </Grid>
    </Box>
  )

}

export default DateSlider

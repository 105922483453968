import React, {
  useEffect,
  useState,
  createRef,
} from 'react'

import {Typography, Grid, Box, Toolbar, Container} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { LoadingScreen } from '_templates'
import {  crewpleteLogo } from '_assets'

const styles = makeStyles(theme => ({
  clientLogo: {
    height: '40px',
    position: 'absolute',
    right: '5px',
  },
  header: {
    backgroundColor: theme.palette.navyBlue.main,
    color: theme.palette.primary.contrastText,
  },
  crewpleteLogo: {
    height: '50px',
  },
  companyName: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoText: {
    display: 'inline-block',
  },
  headerGrids: {
    display: 'flex',
    alignItems: 'center',
  },
  imgError: {
    display: 'none',
  }
} ))


const renderLogo = (productType, classes) => {
  const shouldRenderLogo = productType !== 'lnb'
  return shouldRenderLogo && <img src={crewpleteLogo} className={classes.crewpleteLogo} alt="CrewpleteLogo" />;
}

const renderClient = (classes, company) => {
  if (company){
    const stdDigitId = Array(3 - company.id.length + 1).join("0") + company.id
    const clientBaseLogoUrl = `https://linknbuild-production-assets.s3.eu-west-2.amazonaws.com/companies/logos/000/000/${stdDigitId}/original/${company.logo_file_name}`;

    const handleImgError = (e) => {
      e.target.className = classes.imgError;
    }
    return (
      <Grid item sm={4} xs={false} className={classes.companyName}>
        <Typography variant='h5' align='right' className = {classes.companyName}>{company.name}</Typography>
        <img onError={handleImgError}src={clientBaseLogoUrl} className={classes.clientLogo} alt="ClientLogo" />
      </Grid>
    );
  } else {
    return null
  }
}

const Layout = ({productType, maxWidth, title, company, children, isLoading = true}) => {
  const [headerWidth, setHeaderWidth] = useState(0);
  const classes = styles();
  const headerRef = createRef();
  const widthThreshold = 600;

  useEffect(() => {
    if (headerRef.current && headerWidth!== headerRef.current.offsetWidth) {
      setHeaderWidth(headerRef.current.offsetWidth)
    }
  }, [headerRef, headerWidth])

  const productText = productType === 'lnb' ? 'LinkNBuild' : 'CrewPlete'
  const logoText = headerWidth > widthThreshold ? productText : ''
  const displayCompany = headerWidth > widthThreshold ? company : ''

  return (
    <Box className = {classes.mainBackground}>
      <Container maxWidth={maxWidth || 'xl'} className={classes.mainContainer}>
        <Toolbar ref={headerRef} className = {classes.header}variant='dense'>
          <Grid container>
            <Grid item sm={4} xs={2} className={classes.headerGrids}>
              {renderLogo(productType, classes)}
              <Typography variant='h5' align='left' className = {classes.logoText}>{logoText}</Typography>
            </Grid>
            <Grid item sm={4} xs={10} className={classes.title}>
              <Typography variant='h4' align='center' className = {classes.title}>{title}</Typography>
            </Grid>
            {renderClient(classes, displayCompany)}
          </Grid>
        </Toolbar>
        {isLoading ? (<LoadingScreen/>) : children}
      </Container>
    </Box>
  )
}
export default Layout 

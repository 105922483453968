import React from 'react'

import { format } from 'date-fns'


import {Dialog, DialogContent, Button, IconButton, Box, Link, Typography} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import AccessCardIcon from '@mui/icons-material/CreditScore'
import makeStyles from '@mui/styles/makeStyles'

import {AccessCard} from '_templates'
import {sortByParam} from '_services'

const styles = makeStyles(theme => ({
  profilePhoto:{
    borderRadius: '300px',
    margin: '30px',
    width: '300px',
  },
}))

const EmployeeProfile = (props) => {
  const {
    isLoadingAttendanceDetails, 
    clearProfile, 
    company, 
    employeeAttendanceDetails, 
    profile, 
    requirements,
  } = props

  const classes = styles()
  if (profile){
    const uri = `https://lnb-ai-facial-rec.s3.eu-west-2.amazonaws.com/saved-faces/main_${profile.id}.jpg`
    const renderAttendanceDetails = () => {
      if (isLoadingAttendanceDetails){
        return(
          <Typography variant='body2'>LOADING...</Typography>
        )
      } else {
        const eapTimeText = (eaps) => {
          let firstStartStr = sortByParam([...eaps],'start_time','asc')[0].start_time
          let lastFinishStr = sortByParam([...eaps], 'finish_time','desc')[0].finish_time
          let firstStart = ""
          if (firstStartStr){
            firstStart = format(new Date(firstStartStr), "HH:mm")
          }
          let lastFinish = null
          if (lastFinishStr){
            lastFinish = format(new Date(lastFinishStr), "HH:mm")
          }

          const finishTimeText = lastFinishStr ? ` - ${lastFinish}` : ""
          return `${firstStart}${finishTimeText}`
        }
        const attendanceHistory = () => employeeAttendanceDetails.actual_employee_days.map((aed) => {
          const historyText = `${format(new Date(aed.date), "ccc dd MMM")} - ${aed.project.name} - ${eapTimeText(aed.employee_activity_periods)}`
          return (<Typography variant='body2'>{historyText}</Typography>)
        })
        return(
          <Box>
            {attendanceHistory()}
          </Box>
        )
      }
    }

    const { accessDetails } = profile

    const inductionStepIds = requirements
      .filter((r) => r.title === 'Induction')
      .map((r) => r.completion_steps.map((cs) => cs.id))
      .flat()

    let inductedProjects = new Set(profile.completionMarks
      .filter((cm) => inductionStepIds.indexOf(cm.completion_step_id) !== -1)
      .map((cm) => cm.completion_step.completion_requirement.project.name))
      .join(", ")

    const stdDigitId = Array(3 - company.id.length + 1).join("0") + company.id
    const clientBaseLogoUrl = `https://linknbuild-production-assets.s3.eu-west-2.amazonaws.com/companies/logos/000/000/${stdDigitId}/original/${company.logo_file_name}`;

    const accessCardProps = {
      name: profile.name,
      surname: profile.surname,
      companyName: profile.companyName,
      position: profile.position,
      profileImgSrc: uri,
      accessibleSitesTxt: inductedProjects,
      logoImgSrc: clientBaseLogoUrl,
    }

    return (
      <Dialog open={profile !== null} onClose={() => clearProfile()} >
        <DialogContent>
          <Button onClick={() => console.log(profile)} >
            <IconButton aria-label="delete" size="large">
              <AccessCardIcon/>
            </IconButton>
          </Button>
          <img className={classes.profilePhoto} src={uri} alt={`profile_photo_${profile.id}`} />
          <Link href={`https://linknbuild.com/employees/${profile.id}/edit`} {...props} >
            <IconButton aria-label="delete" size="large">
              <EditIcon />
            </IconButton>
          </ Link>
          <div>
            <AccessCard {...accessCardProps}/>
          </div>
          <br/>
          <Typography variant='subtitle2'>Attendance history:</Typography>
          {renderAttendanceDetails()}
        </DialogContent>
      </Dialog>
    )
  } else return null
}

export default EmployeeProfile

import React, { useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelect = (props) => {
  const {collection, onSelect, selection, searchAttribute} = props;
  const [searchTerm, setSearchTerm] = useState('');

  const isAllSelected =
    collection.length > 0 && selection && selection.length === collection.length;

  const handleSelection = (event, value) => {
    onSelect(event, value, searchTerm);
  }

  const handleInputChange = (event, value) => {
    setSearchTerm(value);
  }

    return (
    <Autocomplete
      multiple
      isOptionEqualToValue={(option, value) => option[searchAttribute] && option[searchAttribute] === value[searchAttribute] }
      id="checkboxes-tags-demo"
      options={collection}
      disableCloseOnSelect
      value={selection}
      getOptionLabel={(option) => option[searchAttribute]}
      onChange={handleSelection}
      onInputChange={handleInputChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option[searchAttribute]}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label={searchAttribute} placeholder="All" />
      )}
    />
  );
}

export default MultiSelect;

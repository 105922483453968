import React, {useState} from 'react';
import {
  useParams,
} from 'react-router-dom';

import {IconButton, Box, Card, Tooltip, Typography, Grid} from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles'

import {format, addDays, startOfDay} from 'date-fns'

import { ProjectListSummaryGraph, ProjectDeliveriesSummaryGraph } from '_organisms'
import {DateSlider} from '_molecules'
import {Layout} from '_templates';
import {ReportsApi} from '_services';

const { useGetPlanCommentsQuery, useGetProjectsQuery, useGetPlantDeliveriesQuery } = ReportsApi;

const styles = makeStyles(theme => ({
  introText: {
    width: 300,
    marginRight: 'auto',
    marginLeft: 'auto',
    color: theme.palette.secondary.main,
    fontStyle: 'italic',
  },
  projectListCard: {
    margin: 'auto',
  },
  projectChoiceText: {
    padding: 15,
  },
  projectNameText: {
    padding: 15,
  },
  projectCard: {
    marginHorizontal: 0,
    width: '100%',
  },
}))

const ProjectList = (props) => {
  const [endDate, setEndDate] = useState(startOfDay((new Date())))
  const [startDate, setStartDate] = useState(addDays(endDate, -13))

  const maxDate = startOfDay(new Date())

  const classes = styles();

  let {companyId} = useParams();
  const {data: company, isFetching: isLoadingProject, error: projectError} = useGetProjectsQuery({companyId, startDate: format(startDate,'yyyy/MM/dd'), endDate: format(endDate, 'yyyy/MM/dd')})
  const {data: projectDeliveries, isFetching: isLoadingDeliveries, error: deliveriesError} = useGetPlantDeliveriesQuery({companyId, startDate: format(startDate,'yyyy/MM/dd'), endDate: format(endDate, 'yyyy/MM/dd')})

  const projectIds = company?.projects?.map((p) => p.id)

  const {data: planComments, isFetching: isFetchingPlanComments, error: planCommentsError} = useGetPlanCommentsQuery({projectIds, startDate: format(startDate, 'yyyy/MM/dd'), endDate: format(endDate, 'yyyy/MM/dd')}, {skip: !projectIds})

  const isLoading = isLoadingProject || isLoadingDeliveries
  //const error = [...projectError, ...deliveriesError]

  const theme = useTheme()

  const renderDeliveriesGraph = (project) => {
    const deliveriesData = projectDeliveries.filter((p) => p.project_id/1 === project.id/1)[0]
    const planCommentsData  = planComments?.filter((p) => p.id/1 === project.id/1)[0]?.plan_comments
    if (deliveriesData) {
      return(
        <>
          <Typography variant='h6' className={classes.projectNameText}>Crane lifts</Typography>
          <ProjectDeliveriesSummaryGraph
            colors={{
              textColor: theme.palette.primary.main,
              plannedColor: theme.palette.green.main,
              unplannedColor: theme.palette.red.main,
              commentsHighlightColor: theme.palette.warning.main,
            }}
            deliveriesData={deliveriesData.deliveries}
            planCommentsData={planCommentsData}
          />
        </>
      )
    } else return null
  }


  const renderProjects = () => {
   return company.projects.map((project) => {
        return(
          <Grid item lg={company.projects.length > 1 ? 6 : 12} xs={12} key={project.id}>
            <Card raised={true} className={classes.projectCard}>
              <Typography variant='h5' className={classes.projectNameText}>
                {project.name}
              </Typography>
              <Typography variant='h6' className={classes.projectNameText}>
                Attendance 
                <Tooltip title='View project attendance dashboard'>
                  <IconButton href={`/dashboard/${companyId}/${project.id}`} style={{marginTop: '-5px'}} >
                    <DashboardIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <ProjectListSummaryGraph
                colors={{checkIn: theme.palette.primary.main, checkOut: theme.palette.navyBlue.main, registration: theme.palette.secondary.main}}
                projectListData={project.aed_counts}
              />
              {renderDeliveriesGraph(project)}
            </Card>
          </Grid>
        )

    })
  }

  return(
    <Layout
      isLoading={isLoading}
      title='All Projects Dashboard'
      company={company?.company}
    >
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card raised={true}>
              <Box height={100} className={classes.settingsSection}>
                <DateSlider 
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  maxDate={maxDate}
                />
              </Box>
            </Card>
          </Grid>
        {!isLoading && renderProjects() }
      </Grid>
    </Layout>
  )
}

export default ProjectList

import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import {PrivateRoute} from '_utils';
import {
  LivePlantDashboard,
  Login,
  PlantAnalysis,
  ProjectList,
  ProjectDashboard,
  SimSummary,
  EmployeeTimes
} from '_scenes';

export default function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/dashboard/:companyId/:projectId" element={<ProjectDashboard/>} />
        <Route path="/projects/:projectId/live-plant-dashboard" element={<LivePlantDashboard/>} />
        <Route path="/projects/:projectId/plant-analysis" element={<PlantAnalysis/>} />
        <Route path="/dashboard/:companyId" element={<ProjectList/>} />
        <Route path="/employees/employee_times/ab_subcontractors" element={<EmployeeTimes projectIds={[22]} companyId={117} companyNameText='ab subcontractor'/>} />
        <Route path="/sim_summary" element={<SimSummary/>} />
      </Route>
    </Routes>
  );
}

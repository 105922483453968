import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { ReportsApi, MainAppApi } from '_services'
import { authReducer } from '_reducers'

export const store = configureStore({
  reducer: {
    [ReportsApi.reducerPath]: ReportsApi.reducer,
    [MainAppApi.reducerPath]: MainAppApi.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ReportsApi.middleware).concat(MainAppApi.middleware),
})
setupListeners(store.dispatch)

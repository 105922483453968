import React, {useState} from 'react' 

import {Card, Grid} from '@mui/material'
import {DataGrid, gridNumberComparator} from '@mui/x-data-grid'

import {format} from 'date-fns'

import {Layout} from '_templates'
import {SimSummaryGraph} from '_organisms'
import {ReportsApi} from '_services'

const { useGetSimSummaryQuery } = ReportsApi

const SimSummary = (props) => {
  const [selectedDevices, setSelectedDevices] = useState([])
  
  const {data: simData, isFetching: isLoading, error} = useGetSimSummaryQuery()

  const simGraphData = simData && simData.filter((d) => {
    const isMissingSim = (d.sim_card === null)
    const isFilteredOut = selectedDevices.length > 0 && selectedDevices.indexOf((d.id)) === -1
    return !(isMissingSim || isFilteredOut)
  })

  const getLastBal = (balances) => balances[balances.length - 1]

  const getLastBalText = (lastBal) => {
    if (!lastBal) return ''
    const val = lastBal.balance
    const valDate = lastBal.created_at
    return `${parseInt(val/1024)}mb (${format(new Date(valDate), 'HH:mm ccc d MMM')})`
  }

  const rows = simData && simData.map((d) => ({
    id: d.id,
    projectId: d.projectId,
    name: d.name,
    start: d.start,
    finish: d.finish,
    phNumber: d.sim_card && d.sim_card.phNumber,
    lastBal: d.sim_card && getLastBal(d.sim_card.balances)
  }))

const balanceComparator = (v1, v2, param1, param2) => gridNumberComparator(v1?.balance, v2?.balance, param1, param2)

  const columns = [
    {field: 'projectId', headerName: 'Project ID', width: 50},
    {field: 'name', headerName: 'Name', width: 350},
    {field: 'start', headerName: 'Start', width: 150},
    {field: 'finish', headerName: 'Finish', width: 150},
    {field: 'phNumber', headerName: 'Phone number', width: 200},
    {
      field: 'lastBal',
      headerName: 'Last balance',
      width: 200,
      valueFormatter: (d) => d && getLastBalText(d.value),
      sortComparator: balanceComparator,
    }
  ]

    return(
      <Layout
        title={`Sim Card dashboard`}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card raised >
              <SimSummaryGraph
                simData = {simGraphData}
              />
            </Card>
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(e) => setSelectedDevices(e)}
              onFilterModelChange={(e) => console.log(e)}
              initialState = {{
                sorting: {
                  sortModel: [
                    {
                      field: 'lastBal',
                      sort: 'asc'
                    }
                  ]
                },
                filter: {
                  filterModel: {
                    items: [{ columnField: 'finish', operatorValue: 'isEmpty'}]
                  }
                }
              }}
            />
            <Card raised >

            </Card>
          </Grid>
        </Grid>
      </Layout>
    )
  }


export default SimSummary

import axios from 'axios';

const env = process.env.NODE_ENV;

const baseURL= (env === 'production') ? 'https://lnb.ai/api/v1' : 'http://localhost:3001'

let APICall = axios.create({
  baseURL: baseURL,
  timeout: 120000,
});

export const configToken = (token) => {
  APICall.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export const getClientToken = async (token, setToken) => {
  const localToken = localStorage.getItem('token')    
  if (localToken) {
    setToken(localToken);
    configToken(localToken);
    return true;
  } else {
    if(token){
      setToken(token, 'getclienttoken - non local')
    }
  }
};

export default APICall;

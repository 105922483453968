/* eslint-disable no-unused-vars */
import { createTheme, adaptV4Theme } from '@mui/material/styles';

const WHITE100 = '#FFFFFF';
const BLACK100 = '#1C1C1C';
const BLACK200 = '#8D8D94';
const BLACK300 = '#BABBC2';
const BLACK400 = '#E6E6EB';
const BLACK500 = '#F0F0F5';
const LINKNBLUE100 = '#01579B';
const LINKNBLUE200 = 'rgba(1, 87, 155, 0.5)';
const NAVYBLUE100 = '#002F6C';
const NAVYBLUE200 = 'rgba(0, 47, 108, 0.5)';
const PALEBLUE100 = '#4F83CC';
const PALEBLUE200 = 'rgba(79, 131, 204, 0.5)';
const STEELE100 = '#546E7A';
const STEELE200 = 'rgba(84, 110, 122, 0.5)';
const CONCRETE100 = '#819CA9';
const CONCRETE200 = 'rgba(129, 156, 169, 0.5)';
const SLATE100 = '#29434E';
const SLATE200 = 'rgba(41, 67, 78, 0.5)';
const GREEN100 = '#73C64C';
const GREEN200 = '#D5EDC9';
const RED100 = '#E15555';
const RED200 = '#F5CCCC';
const CTACANARY100 = '#FFDA00';
const CTACANARY200 = 'rgba(0, 47, 108, 0.5)';

const theme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tvScreen: 2500,
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '100px',
      },
    },
  },
  palette: {
    primary: {
      main: LINKNBLUE100,
    },
    secondary: {
      main: STEELE100,
    },
    action: {
      main: CTACANARY100,
    },
    error: {
      main: RED100,
    },
    warning: {
      main: CTACANARY100,
    },
    success: {
      main: GREEN100,
    },
    info: {
      main: BLACK300,
    },
    linknblue: {
      main: LINKNBLUE100,
    },
    steele: {
      main: STEELE100,
    },
    slate: {
      main: SLATE200,
    },
    concrete: {
      main: CONCRETE200,
    },
    navyBlue: {
      main: NAVYBLUE100,
    },
    paleBlue: {
      main: PALEBLUE100,
    },
    red: {
      main: RED100,
    },
    green: {
      main: GREEN100,
    },
  },
}));

export default theme;

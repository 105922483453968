import React, {
  useState,
} from 'react'
import { useNavigate, useLocation} from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setCredentials } from '_reducers'
import { LoginForm, Layout } from '_templates'
import { ReportsApi } from '_services'


const Login = (props) => {
  const [passcode, setPasscode] = useState('')
  const [rememberMe, setRememberMe] = useState(true)
  const [formErrorText, setFormErrorText] = useState('')
  const navigation = useNavigate()
  const dispatch = useDispatch()

  const { usePostLoginMutation } = ReportsApi

  const [login, {isLoading}] = usePostLoginMutation()

  const location = useLocation();

  const submitPasscode = async () => {
    try {
      const {data} = await login({params: {passcode: passcode}})
      dispatch(setCredentials({user: data.access, token: data.token, userType: 'direct'}))
      if(rememberMe){
        const profileString = JSON.stringify({token: data.token, userType: 'direct'})
        localStorage.setItem('profile', profileString)
      }
      const origin = location.state?.from?.pathname || '/';
      navigation({pathname: origin})
    } catch(error) {
      if (error.response){
        console.log(error.response.data)
        setFormErrorText(error.response.data)
      } else {
        console.log(error)
      }
    }
  }

  return (
    <Layout
      title={`Dashboard access`}
      isLoading={isLoading}
    >
      <LoginForm
        passcode={passcode}
        setPasscode={setPasscode}
        formErrorText={formErrorText}
        submitPasscode={submitPasscode}
        rememberMe={rememberMe}
        setRememberMe={setRememberMe}
      />
    </ Layout>
  )
}


  export default Login

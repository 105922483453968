import React, {
  useState,
} from 'react'
import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'

import {store} from '_redux'

import {
  getISODay, 
  addDays, 
  startOfDay, 
  format,
} from 'date-fns'

import {zonedTimeToUtc} from 'date-fns-tz'

import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import {Dialog, DialogTitle, DialogContent, Button, Card, Grid, Box, Typography} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import {Layout} from '_templates'
import {SiteSummaryGraph, SiteDayAttendanceGraph, SiteAttendanceDetails, EmployeeProfile } from '_organisms'
import {WeekPicker, MultiSelect} from '_molecules';
import {ReportsApi, downloadFile, configToken} from '_services'

const styles = makeStyles(theme => ({
  settingsSection: {
    margin: '0px',
    padding: '3px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  reportTypeButton: {
    display: 'block',
    margin: 'auto',
    marginBottom: '10px',
  },
}))

const getLastSunday = (date = new Date()) => {
  const dayOfWeek = getISODay(date)
  const sundayDate = addDays(date, -dayOfWeek)
  return zonedTimeToUtc(startOfDay(sundayDate), 'UTC')
}

const ProjectDashboard = (props) => {
  const currentDate=  zonedTimeToUtc(startOfDay(new Date()), 'UTC')
  const [reportDate, setReportDate]  = useState(getLastSunday())
  const [frozenDate, setFrozenDate] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [isTimeFrozen, setIsTimeFrozen] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(currentDate)
  const [reportDownloadParamsSelectStep, setReportDownloadParamsSelectStep] = useState({step: null})
  const [reportDownloadParams, setReportDownloadParams] = useState({})
  const [employeeProfileData, setEmployeeProfileData] = useState(null)
  const [filtered, setFiltered] = useState([])
  const navigate = useNavigate();
  const location = useLocation()

  let {projectId, companyId} = useParams()
  const {useGetProjectDashboardQuery, useGetProjectsQuery, useGetEmployeeAttendanceDetailsQuery, useGetProjectCompletionRequirementsQuery} = ReportsApi
  const classes = styles()

  const theme = useTheme()

  const {data: projectData, isFetching: isLoadingProjectData, error: projectDataError}= useGetProjectDashboardQuery({companyId, projectId, startDate: reportDate})

  const {data: requirements, isFetching: isLoadingRequirements, error: requirementsError}= useGetProjectCompletionRequirementsQuery(projectId)

  const {data: company, isFetching: isLoadingCompany, error: companyError} = useGetProjectsQuery({companyId})

  const skipEmployeeProfileFetch = !employeeProfileData

  const {
    data: employeeAttendanceDetails, 
    isFetching: isLoadingAttendanceDetails, 
    error: employeeAttendanceDetailsError
  } = useGetEmployeeAttendanceDetailsQuery(
    {
      companyId, 
      employeeProfileDataId: employeeProfileData?.id, 
      startDate: reportDate
    }, 
    {
      skip: skipEmployeeProfileFetch
    }
  ) 

  const downloadReport = (startDate, endDate, reportType) => {
    setIsDownloading(true)
    const {getState} = store
    const token = (getState()).auth.profile.token
    configToken(token)
    const fileName = `${projectData.project.name} ${reportType} attendance (${format(new Date(startDate),'dd-MM-yy')} to ${format(new Date(endDate),'dd-MM-yy')}).xlsx`
    const timezoneOffset = new Date().getTimezoneOffset()
    const reportParams = {
      report_time: format(new Date(), 'yyyy-MM-dd HH:mm'), 
      project_id: projectId, 
      start_date: startDate, 
      end_date: endDate, 
      report_type: reportType, 
      timezone_offset: timezoneOffset 
    }
    downloadFile('excel_download', fileName, reportParams)
      .then(() => setIsDownloading(false))
  }

  const errors = [projectDataError, companyError, employeeAttendanceDetailsError, requirementsError]
  const authErrors = errors.filter((err) => err && err.originalStatus === 401 )
  if (authErrors.length > 0){
    navigate('/login', {replace: true, state: {from: location}})
  }

  const freezeDate = (date) => {
    setFrozenDate(date)
  }

  const toggleIsTimeFrozen = () => {
    setIsTimeFrozen(!isTimeFrozen)
  }

  const selectDate = (date) => {
    setSelectedDate(date)
  }

  const handleReportDateChange = (date) => {
    selectDate(date)
    date = getLastSunday(date)
    setReportDate(date)
  }

  const displayDate = frozenDate ? frozenDate : selectedDate

  let onSiteData, throughputData, personnelData

  const isLoading = isLoadingCompany || isLoadingProjectData || isDownloading || isLoadingRequirements


  const onSelect = (event, newValue) => {
    setFiltered(newValue);
  }

  let uniquePositions = []

  if (!isLoading && authErrors.length === 0) {
    onSiteData = projectData.dayProfile.onSite.filter(
      (day) => new Date(day.date)/1 === displayDate/1 
    )[0]

    throughputData = projectData.dayProfile.throughput.filter(
      (day) => new Date(day.date)/1 === displayDate/1
    )[0]

    personnelData = projectData.personnel.filter(
      (day) => new Date(day.date)/1 === displayDate/1
    )[0]

    uniquePositions = new Set(projectData.personnel.map((d) => d.personnel.map((co) => co.personnel.map((e) => e.position))).flat(4));
    uniquePositions = [...uniquePositions].sort((a,b) => {
      if(a > b) return 1;
      if(a < b) return -1;
      return 0;
    })

    uniquePositions = uniquePositions.map((pos) => ({position: pos})).filter((pos) => pos.position !== null);
  }

  const renderDayAttendanceGraphs= () => {
    if (onSiteData) {
      if (onSiteData.onSiteCounts.length === 0) {
        return(
          <Card raised={true} >
            <Typography variant='h5'>
              No attendance data available for this date. 
            </Typography>
          </Card>
        )
      }
      return(
        <Card raised={true}>
          <SiteDayAttendanceGraph 
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            isTimeFrozen={isTimeFrozen}
            toggleIsTimeFrozen={toggleIsTimeFrozen}
            onSiteData={onSiteData} 
            throughputData={throughputData} 
            colors={{onSite: theme.palette.secondary.light}}
          />
        </Card>
      )
    } else return null
  }

  const renderPersonnelDetails= () => {
    if (personnelData && personnelData.personnel.length >0) {
      return(
        <SiteAttendanceDetails
          selectedTime={selectedTime}
          requirements={requirements}
          filtered={filtered}
          personnelData={personnelData}
          companyId={companyId}
          setEmployeeProfile={(person) => setEmployeeProfileData(person)}
        />
      )
    } else return null
  }

  const startDownloadParamsSelect = () => {
    setReportDownloadParamsSelectStep({step: 1, prompt: 'Report start date:', default: reportDate})
  }

  const handleDownloadParamsSelect = (selection) => {
    const {startDate, endDate} = reportDownloadParams
    if (reportDownloadParamsSelectStep.step === 1) {
      setReportDownloadParams({startDate: selection})
      setReportDownloadParamsSelectStep({step: 2, prompt: 'Report end date:', default: addDays(reportDate, 6)})
    } else if (reportDownloadParamsSelectStep.step === 2) {
      setReportDownloadParams({...reportDownloadParams, endDate: selection})
      setReportDownloadParamsSelectStep({step: 3, prompt: 'Choose report type:', default: addDays(reportDate, 6)})
    }else if (reportDownloadParamsSelectStep.step === 3) {
      setReportDownloadParams({...reportDownloadParams, reportType: selection})
      downloadReport(startDate, endDate, selection)
      setReportDownloadParams({})
      setReportDownloadParamsSelectStep({step: null})
    }
  }

  const siteSummaryColors = {checkIn: theme.palette.primary.main, checkOut: theme.palette.navyBlue.main, registration: theme.palette.secondary.main}
  const renderSelectDetails = () => {
    if(reportDownloadParamsSelectStep.step < 3){
      return(
        <DialogContent>
          <DatePicker 
            value={reportDownloadParamsSelectStep.default}
            variant='static' 
            onChange={(date) => handleDownloadParamsSelect(date)}
            disableToolbar
            renderInput={(props) => <TextField {...props} />}
          />
        </DialogContent>
      )
    } else {
      return(
        <DialogContent className={classes.buttonSelectSection}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => handleDownloadParamsSelect('summary')}
            className={classes.reportTypeButton}

          >Summary</Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => handleDownloadParamsSelect('detailed')}
            className={classes.reportTypeButton}
          >Detailed</Button>
        </DialogContent>
      )
    }
  }

  const showMultiselect = () => {
    if (false){
      return(
        <MultiSelect collection={uniquePositions}
          searchAttribute='position'
          onSelect={onSelect}
          selection={filtered}
        />
      )
    } else return null
  }

  return(
    <Layout
      title={`${ projectData ? projectData.project.name : ''} dashboard`}
      isLoading={isLoading}
      company={company ? company : null}
    >
      {!isLoading && authErrors.length === 0 &&
      <div>
        <EmployeeProfile
          requirements={requirements}
          profile={employeeProfileData}
          company={company}
          clearProfile={() => setEmployeeProfileData(null)}
          isLoadingAttendanceDetails={isLoadingAttendanceDetails}
          employeeAttendanceDetails={employeeAttendanceDetails}
        />
        <Dialog open={reportDownloadParamsSelectStep.step !== null} onClose={null}>
          <DialogTitle>{reportDownloadParamsSelectStep.prompt}</DialogTitle>
          {renderSelectDetails()}
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card raised={true}>
              <Box className={classes.settingsSection}>
                <WeekPicker 
                  handleReportDateChange={(d) => handleReportDateChange(d)} reportDate={reportDate}
                />
                <Button 
                  variant='contained' 
                  color='secondary'
                  onClick={() => startDownloadParamsSelect()}
                >
                  DOWNLOAD
                </Button> 
                {showMultiselect()}
              </Box>
            </Card>
          </Grid>
          <Grid item lg={5} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card raised={true}>
                  <SiteSummaryGraph
                    colors = {siteSummaryColors}
                    freezeDate = {(date) => freezeDate(date)}
                    frozenDate = {frozenDate}
                    selectDate = {(date) => selectDate(date)}
                    selectedDate = {selectedDate}
                    siteData = {projectData.summary}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                {renderDayAttendanceGraphs()}
              </Grid>
            </Grid>
          </Grid>
        <Grid item lg={7} xs={12}>
          <Card raised={true} >
            {renderPersonnelDetails()}
          </Card>
        </Grid>
        </Grid>
      </div>
      }
    </Layout>
  )
}


export default ProjectDashboard

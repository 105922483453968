import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentProfile } from '_reducers';

const useAuth = () => {
  const profile= useSelector(selectCurrentProfile)

  return useMemo(() => ({ profile}), [profile])
}

export default useAuth;

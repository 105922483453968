import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'auth',
  initialState: { profile: {token: null, userType: null}},
  reducers: {
    setCredentials: ( state, action) => {
      state.profile = action.payload
    },
  },
})

export const { setCredentials } = slice.actions

export default slice.reducer

export const selectCurrentProfile = (state) => state.auth.profile

import React, {
  useState,
} from 'react';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {isSameDay, format, eachDayOfInterval} from 'date-fns';
import styles from './EmployeeTnATableStyles';

import {idSplitter, sortByParam} from '_services'

const EmployeeTnATable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const classes = styles();

  const {employees, startDate, endDate} = props;

  const dateRng = startDate && endDate ? eachDayOfInterval({start: new Date(startDate), end: new Date(endDate)}) : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return(
    <>
      <TableContainer sx={{ maxHeight: window.innerHeight - 100}}>
        <Table stickyHeader aria-label='employees tna' size='small'>
          <TableHead>
            <TableRow>
              <TableCell
                key={'employeeTitle'}
                rowSpan={2}
                className={classes.employeeTitleCell}
              >
                Employee
              </TableCell>
              {dateRng.map((date, i) => {
                const backgroundColor = (i % 2) === 0 ? '#EEE' : '#FFF'
                return (
                  <TableCell
                    style={{backgroundColor: backgroundColor}}
                    className={classes.dateHeadings}
                    align='center'
                    colSpan={2}
                    key={format(date, "dd MMM")}
                  >
                    {format(date, "eee dd MMM")}
                  </TableCell>
                )}
              )}
            </TableRow>
            <TableRow >
              {dateRng.map((date, i) => {
                const backgroundColor = (i % 2) === 0 ? '#EEE' : '#FFF'
                const cellStyles = {backgroundColor: backgroundColor}
                return (
                  <React.Fragment key={date}>
                    <TableCell style={cellStyles} className={classes.inOutHeader}>
                      In
                    </TableCell>
                    <TableCell style={cellStyles} className={classes.inOutHeader}>
                      Out 
                    </TableCell>
                  </React.Fragment>
                )
              }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((employee) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={employee.id}>
                      <TableCell key={`${employee.id}-name`} component='a' href={`https://linknbuild.com/employees/${employee.id}/edit`} target='_blank' className={classes.employeeNameColumn}>
                        <img 
                          height='30px' 
                          width='30px' 
                          alt='profile_pic' 
                          className={classes.employeeAvatar}
                          src={`https://s3.eu-west-2.amazonaws.com/linknbuild-production-assets/employees/photos/${idSplitter(employee.id)}/thumb/profile_photo.jpg`} 
                        />
                        <span>
                          {employee.name} {employee.surname} ({employee.actual_employee_days.length})
                        </span>
                      </TableCell>
                      {dateRng.map((date, i) => {
                        const backgroundColor = (i % 2) === 0 ? '#EEE' : '#FFF'
                        const aed = employee.actual_employee_days.find((aed) => isSameDay(new Date(aed.date), date))
                        let startTime, finishTime = null;
                        if (aed) {
                          const first_eap = sortByParam(aed.employee_activity_periods, 'start_time', 'asc')[0]
                          const last_eap = sortByParam(aed.employee_activity_periods, 'finish_time', 'asc')[0]
                          startTime = first_eap ? format(new Date(first_eap.start_time), 'HH:mm') : null
                          finishTime = last_eap && last_eap.finish_time ? format(new Date(last_eap.finish_time), 'HH:mm'): null
                        }
                        return (
                          <React.Fragment key={`${date}-${employee.id}`}>
                            <TableCell 
                              key={`${employee.id}-${date}-start`}
                              style={{backgroundColor: backgroundColor}}
                            >
                              {startTime}
                            </TableCell>
                            <TableCell 
                              key={`${employee.id}-${date}-end`}
                              style={{backgroundColor: backgroundColor}}
                            >
                              {finishTime}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={employees.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default EmployeeTnATable

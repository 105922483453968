import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(theme => ({ 
  employeeTitleCell: {
    minWidth: 250, 
    position: 'sticky', 
    left: 0, 
    zIndex: 9999, 
    backgroundColor: '#FFF'
  },
  dateHeadings: {
    minWidth: 80,
  },
  inOutHeader: {
    top: 37,
  },
  employeeNameColumn: {
    textDecoration: 'none', 
    position: 'sticky', 
    left: 0, 
    zIndex: 999, 
    backgroundColor: '#FFF',
    "&:hover": {
      fontWeight: 'bold',
    },
  },
  employeeAvatar: {
    marginTop: '-7px', 
    marginBottom: '-7px', 
    borderRadius: '15px', 
    paddingRight: '10px'
  },
}))

export default styles;

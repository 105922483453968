import React, { useRef, useEffect } from 'react'
import {format} from 'date-fns'

const getFittedFontSize = (ctx, text, maxFontSize, spaceWidth) => {
  const textWidth = ctx.measureText(text).width
  return textWidth > spaceWidth ? Math.round(maxFontSize * (spaceWidth / textWidth)) : maxFontSize
}
  


const AccessCard = props => {
  
  const canvasRef = useRef(null)
  
  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
      const {
        name,
        surname,
        companyName,
        position, profileImgSrc,
        accessibleSitesTxt,
        logoImgSrc
      } = props

    const profileImg = document.createElement('img')
    const logoImg = document.createElement('img')

    profileImg.src = profileImgSrc
    logoImg.src = logoImgSrc

      const timestamp = format(new Date(), 'HH:mm dd/MM/yy')
      const height = 304
      const width = 434
      const maxLogoHeight = 63
      const maxLogoWidth = 230

    const draw = () => {

      ctx.fillStyle = '#002F6C'
      ctx.fillRect(0,0,width,height)
      ctx.fillStyle = '#FFF'
      ctx.fillRect(4,24,width-8,height-83)
      ctx.font = '20px Roboto'
      ctx.fillText('INDUCTION CARD', 132, 19)
      ctx.font = '40px Roboto'
      ctx.fillStyle = '#000'
      const nameFontSize = getFittedFontSize(ctx, name, 40, width - 180)
      ctx.font = `${nameFontSize}px Roboto`
      ctx.fillText(name, 20, 115)
      ctx.font = '18px Roboto'
      const surnameFontSize = getFittedFontSize(ctx, surname, 18, width - 180)
      ctx.font = `${surnameFontSize}px Roboto`
      ctx.fillText(surname, 20, 136)
      ctx.font = '12px Roboto'
      ctx.fillText('Company', 20, 160)
      ctx.font = '20px Roboto'
      ctx.fillText(companyName, 20, 180)
      ctx.font = '12px Roboto'
      ctx.fillText('Position', 20, 200)
      ctx.font = '20px Roboto'
      ctx.fillText(position, 20, 220)
      ctx.fillStyle = '#FFF'
      ctx.font = '32px Roboto'
      const accessibeSitesFontSize = getFittedFontSize(ctx, accessibleSitesTxt, 32, width - 40)
      ctx.font = `${accessibeSitesFontSize}px Roboto`
      ctx.fillText(accessibleSitesTxt, 20, 280)
      ctx.font = '12px Roboto'
      ctx.fillText(timestamp, 340, 300)
    }

    const drawProfileImg = () => {
      ctx.drawImage(profileImg, width - 4 - 150, 24, 150, 150)
    }

    const drawLogoImg = () => {
      const isWidthConstrainedLogo = (maxLogoHeight / maxLogoWidth) > (logoImg.height / logoImg.width)
      const logoWidth = isWidthConstrainedLogo ? maxLogoWidth : logoImg.width * (maxLogoHeight / logoImg.height)
      const logoHeight= isWidthConstrainedLogo ? logoImg.height* (maxLogoWidth/ logoImg.width) : maxLogoHeight
      ctx.drawImage(logoImg, 4, 24, logoWidth, logoHeight)
    }

    profileImg.addEventListener('load', drawProfileImg)
    logoImg.addEventListener('load', drawLogoImg)

    draw()
    drawProfileImg()
    drawLogoImg()
  }, [])

  return <canvas height='304' width='434' style={{border: '1px solid black'}} fill='#000' ref={canvasRef} {...props}/>
}

export default AccessCard

import React, {
  useLayoutEffect,
} from 'react';
import {
} from 'react-router-dom';

import {isSameDay, startOfDay, endOfDay} from 'date-fns';
import * as d3 from 'd3';

const getTimeIntervals = () => {
  const intervals = [];
  let time = new Date(0, 0, 0, 0, 0); // Start at midnight (00:00)

  while (time.getDate() === 31) {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    intervals.push(formattedTime);

    time.setMinutes(time.getMinutes() + 10); // Increment by 10 minutes
  }

  return intervals;
};

const SiteDayAttendanceGraph= (props) => {
  const {selectedTime, setSelectedTime, isTimeFrozen, toggleIsTimeFrozen, onSiteData, throughputData, colors} = props

  const siteDayAttendanceGraph = React.createRef();

  useLayoutEffect(() => {
    const renderAttendanceGraph = () => {

      const date = onSiteData.date;
      const throughputResolution = (new Date(throughputData.siteThroughputs[1].time)).valueOf() - (new Date(throughputData.siteThroughputs[0].time)).valueOf();
      const wholeDayValue = new Date(0,0,1,0,0).valueOf() - new Date(0,0,0,0,0).valueOf();
      const beginningOfDay = startOfDay(new Date(date));
      const dayEnd = endOfDay(new Date(date));
      const graphEnd = isSameDay(new Date(), new Date(onSiteData.date)) ? new Date() : dayEnd;

      const height = 200;
      const width = 550;
      const onsiteColor = colors.onSite;

      const svg = d3.select(siteDayAttendanceGraph.current)
        .attr('height', height*2)
        .attr('width', width)

      const graphDataAttendance = onSiteData.onSiteCounts;
      const graphDataThroughput = throughputData.siteThroughputs;

      const maxCount = d3.max(graphDataAttendance, (d) => d.count)
      const yScale = d3.scaleLinear()
        .domain([0,maxCount])
        .range([0,height-100])

      const maxThroughput = d3.max(graphDataThroughput, (d) => d.counts.checkIns + d.counts.checkOuts)
      const yScaleThroughput = d3.scaleLinear()
        .domain([0,maxThroughput])
        .range([0,height-100])

      const xScale = d3.scaleTime()
        .domain([beginningOfDay.valueOf(),dayEnd.valueOf()])
        .range([10,width-10])

      const widthScale = d3.scaleLinear()
        .domain([0,dayEnd.valueOf()-beginningOfDay.valueOf()])
        .range([0,width])

      function handleTimeClick(e, d) {
        setSelectedTime(d)
        if(!isTimeFrozen || d === selectedTime) {
          toggleIsTimeFrozen()
        }
      }

      function handleTimeHover(e, d) {
        if (!isTimeFrozen){
          setSelectedTime(d)
        }
      }

      if(svg.nodes()[0].childNodes.length === 0){

        const attendanceGraph = svg
          .append('g')
          .attr('class','attendanceGraph')
          .selectAll('rect')
          .data(graphDataAttendance);

        attendanceGraph
          .enter()
          .append('rect')
          .attr('fill',onsiteColor)
          .attr('stroke',onsiteColor)
          .attr('stroke-width',1)
          .merge(attendanceGraph)
          .attr('x', (d, i) => xScale((new Date(d.time)).valueOf()))
          .attr('y', (d,i) => height - yScale(d.count) - 80)
          .attr('width', (d,i) => {
            if(i < graphDataAttendance.length - 1){
              return widthScale((new Date(graphDataAttendance[i+1].time)).valueOf()-(new Date(d.time)).valueOf());
            }
            return widthScale(graphEnd.valueOf() - d.time.valueOf());
          })
          .attr('height', (d,i) => yScale(d.count) )

        const target = 60/Math.floor(60/d3.min([maxThroughput, 60]))

        const targetData = [
          [{x:beginningOfDay.valueOf(), y: target},{x:dayEnd.valueOf(), y: target}],
          [{x:beginningOfDay.valueOf(), y: target/2},{x:dayEnd.valueOf(), y: target/2}]
        ]

        const targetLines = svg
          .append('g')
          .attr('class','targetLines')
          .selectAll('line')
          .data(targetData)

        targetLines
          .enter()
          .append('path')
          .merge(targetLines)
          .attr('d',d3.line()
            .x((d) => xScale(d.x))
            .y((d)=> height*2 - yScaleThroughput(d.y)-80)
          )
          .attr("fill", "#CCC")
          .attr("stroke", "#CCC")
          .attr("stroke-width", 1)

        const targetLabels = svg
          .append('g')
          .attr('class','targetLabels')
          .selectAll('text')
          .data(targetData)

        targetLabels
          .enter()
          .append('text')
          .attr('font-size', '12')
          .attr('font-style', 'italic')
          .attr('fill', "#AAA")
          .text((d) => `${d[0].y} (${throughputResolution/(1000*d[0].y)} second cycle)`)
          .attr('transform', (d,i) => {
            const x = 10;
            const y = height*2 - yScaleThroughput(d[0].y)-85;
            return `translate(${x},${y})`;
          })

        const throughputGraph = svg
          .append('g')
          .attr('class','throughputGraph')
          .selectAll('rect')
          .data(graphDataThroughput);

        throughputGraph
          .enter()
          .append('rect')
          .attr('fill',onsiteColor)
          .attr('stroke',onsiteColor)
          .attr('stroke-width',1)
          .merge(throughputGraph)
          .attr('x', (d, i) => xScale((new Date(d.time)).valueOf()))
          .attr('y', (d,i) => height*2 - yScaleThroughput(d.counts.checkIns + d.counts.checkOuts) - 80)
          .attr('width', () =>  widthScale(throughputResolution))
          .attr('height', (d,i) => yScaleThroughput(d.counts.checkIns + d.counts.checkOuts))

        const xAxis = g => g
          .attr("transform", `translate(0,${height-80})`)
          .call(d3
            .axisBottom(xScale)
            .tickFormat(d3.timeFormat("%I%p"))
            .tickSize(5)
          )

        const throughputXAxis = g => g
          .attr("transform", `translate(0,${height*2-80})`)
          .call(d3
            .axisBottom(xScale)
            .tickFormat(d3.timeFormat("%I%p"))
            .tickSize(5)
          )

        svg.append('g')
          .attr('class','maxLabel')
          .append('text')
          .attr('y',height - yScale(maxCount) - 85)
          .attr('x',() => {
            const maxRecord = graphDataAttendance.filter((d) => d.count === maxCount)[0]
            if(maxRecord){
              return xScale(new Date(graphDataAttendance.filter((d)=>d.count === maxCount)[0].time).valueOf())
            }
            return 0;
          })
          .text(maxCount)
          .attr('fill',onsiteColor)

        svg.append('g')
          .attr('class','snapshotTitle')
          .append('text')
          .attr('y',12)
          .attr('x',10)
          .text('Day attendance snapshot')
          .attr('fill',onsiteColor)

        svg.append('g')
          .attr('class','throughputTitle')
          .append('text')
          .attr('y',height-20)
          .attr('x',10)
          .text(`${throughputResolution/60000} minute throughput`)
          .attr('fill',onsiteColor)

        let axis = svg
          .append('g')
          .call(xAxis);

        let throughputAxis = svg
          .append('g')
          .attr('class','throughputAxis')
          .call(throughputXAxis);

        throughputAxis.selectAll("path")
          .style("stroke", 'gray');

        axis.selectAll("path")
          .style("stroke", 'gray');


        const timeData = getTimeIntervals();

        const selectors = svg
          .attr('height', height)
          .attr('width',widthScale(wholeDayValue))
          .append('g')
          .attr('class','selectors')
          .selectAll('rect')
          .data(timeData)

        selectors.enter()
          .append('rect')
          .attr('x', (d, i) => 10 + (i * ((width-20) / (24*6))))
          .attr('y', (d,i) => 0)
          .attr('width', (width-20) / (24*6))
          .attr('height', height * 2)
          .merge(selectors)
          .attr('fill', () => isTimeFrozen ? "#0F0" : "#000")
          .attr('fill-opacity',(d, i) => {
            if(selectedTime && d === selectedTime){
              return '.2'
            } else {
              return '0.0'
            }
          })
          .on("mouseenter", handleTimeHover)
          .on('click', handleTimeClick)

      } else {

        const timeData = getTimeIntervals();

        svg
          .select('g.selectors')
          .selectAll('rect')
          .data(timeData)
          .attr('fill', () => isTimeFrozen ? "#0F0" : "#000")
          .attr('fill-opacity',(d, i) => {
            if(selectedTime && d === selectedTime){
              return '.2'
            } else {
              return '0.0'
            }
          })
          .on("mouseenter", handleTimeHover)
          .on('click', handleTimeClick)

        const attendanceGraph = svg
          .select('g.attendanceGraph')
          .selectAll('rect')
          .data(graphDataAttendance);


        attendanceGraph
          .enter()
          .append('rect')
          .attr('fill',onsiteColor)
          .attr('stroke',onsiteColor)
          .attr('stroke-width',1)
          .merge(attendanceGraph)
          .attr('x', (d, i) => xScale((new Date(d.time)).valueOf()))
          .attr('y', (d,i) => height - yScale(d.count) - 80)
          .attr('width', (d,i) => {
            if(i < graphDataAttendance.length - 1){
              return widthScale((new Date(graphDataAttendance[i+1].time)).valueOf()-(new Date(d.time)).valueOf());
            }
            return widthScale(graphEnd.valueOf() - d.time.valueOf());
          })
          .attr('height', (d,i) => yScale(d.count) )

        const target = 60/Math.floor(60/d3.min([maxThroughput, 60]))

        const targetData = [
          [{x:beginningOfDay.valueOf(), y: target},{x:dayEnd.valueOf(), y: target}],
          [{x:beginningOfDay.valueOf(), y: target/2},{x:dayEnd.valueOf(), y: target/2}]
        ]

        const targetLines = svg
          .select('g.targetLines')
          .selectAll('line')
          .data(targetData)

        targetLines
          .enter()
          .select('path')
          .merge(targetLines)
          .attr('d',d3.line()
            .x((d) => xScale(d.x))
            .y((d)=> height*2 - yScaleThroughput(d.y)-80)
          )
          .attr("fill", "#CCC")
          .attr("stroke", "#CCC")
          .attr("stroke-width", 5)

        //const targetLines = svg
        //  .append('g')
        //  .attr('class','targetLines')
        //  .selectAll('line')
        //  .data(targetData)

        //targetLines
        //  .enter()
        //  .append('path')
        //  .attr('d',d3.line()
        //    .x((d) => xScale(d.x))
        //    .y((d)=> height*2 - yScaleThroughput(d.y)-80)
        //  )
        //  .attr("fill", "#CCC")
        //  .attr("stroke", "#CCC")
        //  .attr("stroke-width", 1)

        const targetLabels = svg
          .select('g.targetLabels')
          .selectAll('text')
          .data(targetData)

        targetLabels
          .enter()
          .select('text')
          .attr('font-size', '12')
          .attr('font-style', 'italic')
          .attr('fill', "#AAA")
          .text((d) => `${d[0].y} (${throughputResolution/(1000*d[0].y)} second cycle)`)
          .attr('transform', (d,i) => {
            const x = 10;
            const y = height*2 - yScaleThroughput(d[0].y)-85;
            return `translate(${x},${y})`;
          })

        const throughputGraph = svg
          .select('g.throughputGraph')
          .selectAll('rect')
          .data(graphDataThroughput);

        throughputGraph
          .enter()
          .append('rect')
          .attr('fill',onsiteColor)
          .attr('stroke',onsiteColor)
          .attr('stroke-width',1)
          .merge(throughputGraph)
          .attr('x', (d, i) => xScale((new Date(d.time)).valueOf()))
          .attr('y', (d,i) => height*2 - yScaleThroughput(d.counts.checkIns + d.counts.checkOuts) - 80)
          .attr('width', () =>  widthScale(throughputResolution))
          .attr('height', (d,i) => yScaleThroughput(d.counts.checkIns + d.counts.checkOuts))

        svg
          .select('g.maxLabel')
          .select('text')
          .attr('y',height - yScale(maxCount) - 85)
          .attr('x',() => {
            const maxRecord = graphDataAttendance.filter((d) => d.count === maxCount)[0]
            if(maxRecord){
              return xScale(new Date(graphDataAttendance.filter((d)=>d.count === maxCount)[0].time).valueOf())
            }
            return 0;
          })
          .text(maxCount)
          .attr('fill',onsiteColor)

        throughputGraph.exit().remove();
        attendanceGraph.exit().remove();
      }

    }
    if (siteDayAttendanceGraph.current && onSiteData && throughputData){
      renderAttendanceGraph();
    }
  }, [colors.onSite, onSiteData, siteDayAttendanceGraph, throughputData])

  return(
    <div className='siteDayAttendanceGraph'>
      <svg ref={siteDayAttendanceGraph}/>
    </div>
  )
}

export default SiteDayAttendanceGraph;

import React, {
  useLayoutEffect,
  useState,
  useEffect,
} from 'react';
import {
} from 'react-router-dom';

import {format} from 'date-fns';
import * as d3 from 'd3'; 
const SiteSummaryGraph = (props) => {
  const [sectionWidth, setSectionWidth] = useState();
  const {frozenDate, freezeDate, selectedDate, siteData, selectDate, colors} = props;
  const siteSummaryGraph = React.createRef();
  const sectionRef = React.createRef();

  useEffect(() => {
  if (sectionRef.current && sectionWidth!== sectionRef.current.offsetWidth) {
    setSectionWidth(sectionRef.current.offsetWidth)
  }
  }, [sectionRef, sectionWidth])

  useLayoutEffect(() => {
    const renderSiteSummaryGraph = () => {

      const svg = d3.select(siteSummaryGraph.current);

      const height = 400;
      const spacing = 70;
      const width = sectionWidth;
      const widthFactor = width / 550;
      const barWidth = 20;
      const registrationColor = colors.registration;
      const checkinColor = colors.checkIn;
      const checkoutColor = colors.checkOut;

      const allDatapointsY = siteData
        .map((d)=> [d.registrations, d.checkIns+d.checkOuts])
        .flat()
      const maxHeight = d3.max(allDatapointsY, (d) => d)
      const yScale = d3.scaleLinear()
        .domain([0,maxHeight])
        .range([0,height-100])


      if(svg.nodes()[0].childNodes.length === 0){

        function handleMouseEnter(e, d) {
          if(!frozenDate){
            selectDate(new Date(d.date));
          }
        }

        function handleMouseLeave(e, d) {
          //selectDate(null);
        }

        function handleDateClick(e, d) {
          if(frozenDate){
            freezeDate(null)
          } else {
            freezeDate(new Date(d.date))
          }
        }

        const update = svg
          .attr('height', height)
          .attr('width', width)
          .append('g')
          .selectAll('rect')
          .data(siteData);

        update.enter()
          .append('rect')
          .attr('x', (d, i) => (i * spacing +30)*widthFactor)
          .attr('y', (d,i) => height - yScale(d.registrations) - 80)
          .attr('width', barWidth * widthFactor)
          .attr('height', (d,i) => yScale(d.registrations) )
          .attr('fill',registrationColor)
          .attr('stroke',registrationColor)
          .attr('stroke-width', '1.5')

        update.enter()
          .append('rect')
          .attr('x', (d, i) => (i * spacing +50)*widthFactor)
          .attr('y', (d,i) => height - yScale(d.checkIns) - 80)
          .attr('width', barWidth* widthFactor)
          .attr('height', (d,i) => yScale(d.checkIns))
          .attr('fill',checkinColor)
          .attr('stroke',checkinColor)
          .attr('stroke-width', '1.5')

        update.enter()
          .append('rect') 
          .attr('x', (d, i) => (i * spacing +50)*widthFactor)
          .attr('y', (d,i) => height - yScale(d.checkOuts + d.checkIns) - 80)
          .attr('width', barWidth* widthFactor)
          .attr('height', (d,i) => yScale(d.checkOuts) )
          .attr('fill',checkoutColor)
          .attr('stroke',checkoutColor)
          .attr('stroke-width', '1.5')

        update.enter()
          .append('text')
          .attr('x', (d,i) => (i * spacing + 40)*widthFactor)
          .attr('y', (d,i) => height - yScale(d.registrations) - 85)
          .style("text-anchor", "middle")
          .style('font-size', '12px')
          .style('font-style','italic')
          .style('fill',registrationColor)
          .text((d,i)=> {
            if(d.registrations>0) return d.registrations;
          })

        update.enter()
          .append('text')
          .attr('x', (d,i) => (i * spacing + 60)*widthFactor)
          .attr('y', (d,i) => height - yScale(d.checkIns+d.checkOuts) - 85)
          .style("text-anchor", "middle")
          .style('font-size', '12px')
          .style('font-style','italic')
          .style('fill',checkoutColor)
          .text((d,i)=> {
            if(d.checkIns+d.checkOuts>0) return d.checkIns+d.checkOuts;
          })

        update.enter()
          .append('text')
          .attr('x',(d,i) => (i * spacing + 50)*widthFactor)
          .attr('y',height-60)
          .style("text-anchor", "middle")
          .style('font-size', '14px')
          .style('fill',registrationColor)
          .text((d,i)=> format(new Date(d.date),'ccc'))

        update.enter()
          .append('text')
          .attr('x',(d,i) => (i * spacing + 50)*widthFactor)
          .attr('y',height-45)
          .style("text-anchor", "middle")
          .style('font-size', '14px')
          .style('fill',registrationColor)
          .text((d,i)=> format(new Date(d.date),'d MMM'))

        const x = d3.scaleBand()
          .domain(d3.range(siteData.length))
          .range([0, (siteData.length*70+50)*widthFactor])

        const xAxis = g => g
          .attr("transform", `translate(0,${height-80})`)
          .call(d3
            .axisBottom(x)
            .tickFormat("")
            .tickSize(0)
            .tickSizeOuter(0)
          )

        let axis = svg.append('g')
          .call(xAxis);

        axis.selectAll("path")
          .style("stroke", 'gray');

        svg.append('g')
          .append('rect')
          .attr('x', 10)
          .attr('y', height-30)
          .attr('width', 100)
          .attr('height', 20)
          .attr('fill',registrationColor)

        svg.append('g')
          .append('text')
          .attr('x', 60)
          .attr('y', height-15)
          .style("text-anchor", "middle")
          .attr('font-size', '14px')
          .attr('fill','white')
          .text('Registrations')

        svg.append('g')
          .append('rect')
          .attr('x', 120)
          .attr('y', height-30)
          .attr('width', 100)
          .attr('height', 20)
          .attr('fill',checkinColor)

        svg.append('g')
          .append('text')
          .attr('x', 170)
          .attr('y', height-15)
          .style("text-anchor", "middle")
          .attr('font-size', '14px')
          .attr('fill','white')
          .text('Check ins')

        svg.append('g')
          .append('rect')
          .attr('x', 230)
          .attr('y', height-30)
          .attr('width', 100)
          .attr('height', 20)
          .attr('fill',checkoutColor)

        svg.append('g')
          .append('text')
          .attr('x', 280)
          .attr('y', height-15)
          .style("text-anchor", "middle")
          .attr('font-size', '14px')
          .attr('fill','white')
          .text('Check outs')

        const selectors = svg
          .attr('height', height)
          .attr('width',width)
          .append('g')
          .attr('class','selectors')
          .selectAll('rect')
          .data(siteData)

        selectors.enter()
          .append('rect')
          .attr('x', (d, i) => (i * spacing + 16)*widthFactor)
          .attr('y', (d,i) => 0)
          .attr('width', (spacing - 3)*widthFactor)
          .attr('height', (d,i) => height -40)
          .merge(selectors)
          .attr('fill-opacity',(d, i) => {
            if(!frozenDate && new Date(d.date)/1 === selectedDate/1){
              return '.2'
            } else {
              return '0'
            }
          })
          .attr('stroke-width',3)
          .attr('stroke',(d, i) => {
            if(new Date(d.date)/1 === frozenDate/1){
              return 'gray'
            } else {
              return 'white'
            }
          })
          .on("mouseenter", (e, d) => {
            if(!frozenDate){
              handleMouseEnter(e, d)
            }
          })
          .on("mouseleave", handleMouseLeave)
          .on('click', handleDateClick)

      } else {

        svg
          .select('g.selectors')
          .selectAll('rect')
          .data(siteData)
          .attr('fill-opacity',(d, i) => {
            if(!frozenDate && new Date(d.date)/1 === selectedDate/1){
              return '.2'
            } else {
              return '0'
            }
          })
          .attr('stroke-width',3)
          .attr('stroke',(d, i) => {
            if(new Date(d.date)/1 === frozenDate/1){
              return 'gray'
            } else {
              return 'white'
            }
          })

      }
    }
    if (siteSummaryGraph.current && siteData){
      renderSiteSummaryGraph();
    }
  }, [colors.checkIn, colors.checkOut, colors.registration, freezeDate, frozenDate, sectionWidth, selectDate, selectedDate, siteData, siteSummaryGraph])

  const renderSummaryGraph = () => {
    if (sectionWidth >0) {
      return (

        <svg ref={siteSummaryGraph}/>
      )
    }
  }
  return(
    <div ref={sectionRef} className='siteSummaryGraph'>
      {renderSummaryGraph()}
    </div>
  )
}

export default SiteSummaryGraph;

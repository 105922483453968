import React, {
  useLayoutEffect,
  useState,
  useEffect,
} from 'react';

import {
} from 'react-router-dom';
import {Box, Checkbox, FormControlLabel, Typography} from '@mui/material'
import {format, isEqual, startOfDay, formatDistanceToNow} from 'date-fns'

import {sortByParam} from '_services'

import * as d3 from 'd3'; 
const ProjectDeliveriesSummaryGraph = (props) => {
  const {deliveriesData, colors, planCommentsData} = props;
  const [sectionWidth, setSectionWidth] = useState();
  const projectDeliveriesSummaryGraph = React.createRef();
  const sectionRef = React.createRef();
  const [hiddenPlantIds, setHiddenPlantIds] = useState([]);
  const [showCommentsDate, setShowCommentsDate] = useState(null);

  const mouseOver = (e, d) => {
    setShowCommentsDate(d.date)
  }

  const mouseOut = (e, d) => {
    setShowCommentsDate(null)
  }

  useEffect(() => {
  if (sectionRef.current && sectionWidth!== sectionRef.current.offsetWidth) {
    setSectionWidth(sectionRef.current.offsetWidth)
  }
  }, [sectionRef, sectionWidth])

  const CustomCheckBox = ({label, checked, onChange}) => {
    return <FormControlLabel control={<Checkbox onChange={onChange}/>} checked={checked} label={label} />
  }
const toggleShowPlant = (id) => {
    const isFiltered = hiddenPlantIds.indexOf(id/1) !== -1
    if (isFiltered) {
      setHiddenPlantIds([...hiddenPlantIds.filter((hidden_id) => hidden_id/1 !== id/1)])
    } else {
      setHiddenPlantIds([...hiddenPlantIds, id/1])
    }
  }

  const Legend = () => {
    const plants = []

    deliveriesData.forEach((d) => d.plants.forEach((p) => {
      const existingLabel = plants.filter((l) => l.id === p.id)[0]
      if (!existingLabel) plants.push(p)
    }))

    const renderCheckboxes = () => {
      return sortByParam(plants, 'name', 'asc').map((plant) =>  {
        const isChecked = hiddenPlantIds.filter((id) => id/1 === plant.id/1).length === 0
        return <CustomCheckBox key={plant.id} label={plant.name} checked={isChecked} onChange={() => toggleShowPlant(plant.id)}/>
      })
    }

    return <> {renderCheckboxes()} </>
  }

  useLayoutEffect(() => {
    const renderProjectDeliveriesSummaryGraph = () => {
      const svg = d3.select(projectDeliveriesSummaryGraph.current); 

      const {textColor, plannedColor, unplannedColor, commentsHighlightColor} = colors

      const height = 100;
      const margin = {top: 10, right: 10, bottom: 10, left: 10}
      const width = sectionWidth - margin.left - margin.right;
      const maxBarWidth = 80;
      const barWidth = d3.min([maxBarWidth, (width - margin.left - margin.right) / (deliveriesData.length * 1.5)])
      const spacing = barWidth * 0.5;
      const xAxisHeight = 15
      const labelHeight = 15
      const isXSmallGraph = (barWidth * 1.5) < 20

      const barValue = (d, type) => {
        const includedValues = d.plants.map((val) => {
          if (hiddenPlantIds.length > 0){
            if (hiddenPlantIds.indexOf(val.id/1) !== -1) {
              return 0
            }
          }
          switch (type){
            case 'planned':
              return val.planned_count/1
            case 'unplanned':
              return val.unplanned_count/1
            default:
              return val.planned_count/1 + val.unplanned_count/1
          }
        })
        return d3.sum(includedValues);
      }


      const maxHeight = d3.max(deliveriesData, (d) => barValue(d, 'all'))

      const yScale = d3.scaleLinear()
        .domain([0,maxHeight])
        .range([0,height - margin.top - margin.bottom - xAxisHeight - labelHeight])

      function handleMouseover(d, i) {
        d3.select(this).style('opacity','0.2');
        const [x,y] = d3.pointer(d);
        svg.append("text")
          .attr('id', "lifts-tooltip")
          .attr('x', x)
          .attr('y', y - 20)
          .text(format(new Date(i.date), 'ccc dd MMM: ') + barValue(i, 'all'))
      }

      function handleMouseout(d, i) {
        d3.select(this).style('opacity','0')
        d3.select("#lifts-tooltip").remove()
      }

      if(svg.nodes()[0].childNodes.length === 0){ 

        svg
          .attr('height', height)
          .attr('width', width)

        const plannedSeries = svg
          .append('g')
          .attr('class','planned_series')
          .selectAll('rect')
          .data(deliveriesData);

        plannedSeries.enter()
          .append('rect')
          .attr('x', (d, i) => (i * (spacing + barWidth) + margin.left + spacing / 2))
          .attr('y', (d,i) => height - yScale(barValue(d, 'planned')) - margin.bottom - xAxisHeight)
          .attr('width', barWidth)
          .attr('height', (d,i) => maxHeight > 0 ? yScale(barValue(d, 'planned')) : 0)
          .attr('fill',plannedColor)

        const unplannedSeries = svg
          .append('g')
          .attr('class','unplanned_series')
          .selectAll('rect')
          .data(deliveriesData);

        unplannedSeries.enter()
          .append('rect')
          .attr('x', (d, i) => (i * (spacing + barWidth) + margin.left + spacing / 2))
          .attr('y', (d,i) => height - yScale(barValue(d, 'all')) - margin.bottom - xAxisHeight)
          .attr('width', barWidth)
          .attr('height', (d,i) => maxHeight > 0 ? yScale(barValue(d, 'unplanned')) : 0)
          .attr('fill',unplannedColor)

        const hoverBars = svg
          .append('g')
          .attr('class','hover_bars')
          .selectAll('rect')
          .data(deliveriesData);

        hoverBars.enter()
          .append('rect')
          .attr('x', (d, i) => (i * (spacing + barWidth) + margin.left + spacing / 2))
          .attr('y', (d,i) => height - yScale(barValue(d, 'all')) - margin.bottom - xAxisHeight)
          .attr('width', barWidth)
          .attr('height', (d,i) => maxHeight > 0 ? yScale(barValue(d, 'all')) : 0)
          .style('opacity',0)
          .on('mouseover', handleMouseover)
          .on('mouseout', handleMouseout)

        const seriesLabels = svg
          .append('g')
          .attr('class','series_labels')
          .selectAll('text')
          .data(deliveriesData);

        seriesLabels.enter()
          .append('text')
          .attr('x', (d,i) => (i * (spacing + barWidth)+ margin.left + ((spacing + barWidth) / 2)))
          .attr('y', (d,i) => height - yScale(barValue(d, 'all')) - margin.bottom - 5 - xAxisHeight)
          .style("text-anchor", "middle")
          .style('font-size', '12px')
          .style('font-style','italic')
          .style('font-family','roboto')
          .style('fill',textColor)
          .text((d,i)=> (!isXSmallGraph && (barValue(d, 'all') > 0)) ? barValue(d, 'all') : '' )

        let commentsHighlight = svg
          .append('g')
          .attr('class','comments_highlight')

        if (planCommentsData){
          commentsHighlight = commentsHighlight
            .selectAll('circle')
            .data(planCommentsData)

          const getDayStart = (dateStr) => startOfDay(new Date(dateStr))

          const getDateIndex = (arr, d) => {
            const datesArr = arr.map((dd) => getDayStart(dd.date).valueOf())
            return datesArr.indexOf(getDayStart(d.date).valueOf())
          }

          commentsHighlight
            .enter()
            .append('circle')
            .attr('cx',(d,i) => (getDateIndex(deliveriesData, d) * (spacing + barWidth) + margin.left + ((spacing + barWidth) / 2)))
            .attr('cy',height - margin.bottom - 7)
            .attr('r',10)
            .attr('fill', commentsHighlightColor)
            .on('mouseover',mouseOver)
            .on('mouseout',mouseOut)

        }

        const axisLabels = svg
          .append('g')
          .attr('class','axis_labels')
          .attr('pointer-events','none')
          .selectAll('text')
          .data(deliveriesData);

        axisLabels.enter()
          .append('text')
          .attr('x', (d,i) => (i * (spacing + barWidth)+ margin.left + ((spacing + barWidth) / 2)))
          .attr('y',height - margin.bottom)
          .style("text-anchor", "middle")
          .style('font-size', '14px')
          .style('font-family','roboto')
          .style('fill',textColor)
          .text((d,i)=> !isXSmallGraph ? format(new Date(d.date), 'd') : '')

        const x = d3.scaleBand()
          .domain(d3.range(deliveriesData.length))
          .range([margin.left, (width - margin.right)])

        const xAxis = g => g
          .attr("transform", `translate(0,${height - margin.bottom - xAxisHeight})`)
          .call(d3
            .axisBottom(x)
            .tickFormat("")
            .tickSize(0)
            .tickSizeOuter(0)
          )

        let axis = svg.append('g')
          .call(xAxis);

        axis.selectAll("path")
          .style("stroke", 'gray');

      } else {

        svg.select('g.planned_series')
          .transition()
          .duration(200)
          .selectAll('rect')
          .attr('y', (d,i) => height - yScale(barValue(d, 'planned')) - margin.bottom - xAxisHeight)
          .attr('height', (d,i) => maxHeight > 0 ? yScale(barValue(d, 'planned')) : 0)

        svg.select('g.unplanned_series')
          .transition()
          .duration(200)
          .selectAll('rect')
          .attr('y', (d,i) => height - yScale(barValue(d, 'all')) - margin.bottom - xAxisHeight)
          .attr('height', (d,i) => maxHeight > 0 ? yScale(barValue(d, 'unplanned')) : 0)

        svg.select('g.series_labels')
          .transition()
          .duration(200)
          .selectAll('text')
          .attr('y', (d,i) => height - yScale(barValue(d, 'all')) - margin.bottom - 5 - xAxisHeight)
          .text((d,i)=> (!isXSmallGraph && (barValue(d, 'all') > 0)) ? barValue(d, 'all') : '' )

        let commentsHighlight = svg.select('g.comments_highlight')

        if (planCommentsData){
          commentsHighlight = commentsHighlight
            .selectAll('circle')
            .data(planCommentsData)

          const getDayStart = (dateStr) => startOfDay(new Date(dateStr))

          const getDateIndex = (arr, d) => {
            const datesArr = arr.map((dd) => getDayStart(dd.date).valueOf())
            return datesArr.indexOf(getDayStart(d.date).valueOf())
          }

          commentsHighlight.enter()
            .append('circle')
            .attr('cx',(d,i) => (getDateIndex(deliveriesData, d) * (spacing + barWidth) + margin.left + ((spacing + barWidth) / 2)))
            .attr('cy',height - margin.bottom - 7)
            .attr('r',9)
            .attr('fill', commentsHighlightColor)
            .on('mouseover',mouseOver)
            .on('mouseout',mouseOut)
        }

      }
    }

    if (projectDeliveriesSummaryGraph.current && deliveriesData){
      renderProjectDeliveriesSummaryGraph();
    }

  }, [hiddenPlantIds, colors, sectionWidth, deliveriesData, planCommentsData, projectDeliveriesSummaryGraph])

  const renderSummaryGraph = () => {
    if (sectionWidth > 0) {
      return (
        <svg ref={projectDeliveriesSummaryGraph}/>
      )
    }
  }

  const renderComment = (comment) => {
    const {name, surname} = comment.comment.user.employee
    const createdAtText = formatDistanceToNow(new Date(comment.created_at))
    const nameText = `${name} ${surname} (${createdAtText} ago): `
    const msg = comment.comment.message
    return (
      <Box key={comment.id}>
        <Typography display='inline' sx={{fontWeight: 'fontWeightBold'}}>
          {nameText}
        </Typography>
        <Typography display='inline'sx={{ml:1}}>
          {msg}
        </Typography>
      </Box>
    )
  }

  const renderComments = () => {
    if (showCommentsDate) {
      const comments = planCommentsData.filter((c) => isEqual(new Date(c.date),new Date(showCommentsDate)))
      return  comments.map(renderComment)
    } else return null

  }

  return(
    <div ref={sectionRef} className='projectDeliveriesSummaryGraph' style={{padding: '10px'}}>
      <Legend/>
      {renderSummaryGraph()}
      {showCommentsDate && 
        <Box>
          <Typography variant='h6'>Comments for {format(new Date(showCommentsDate),'ccc d MMM')}</Typography>
          {renderComments()}
        </Box>
      }
    </div>
  )
}

export default ProjectDeliveriesSummaryGraph;

import React from 'react';
import Box from '@mui/material/Box';
import {
  isSameDay,
  endOfWeek,
  startOfWeek,
  isWithinInterval
} from 'date-fns';

import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import PickersDay from '@mui/lab/PickersDay';

  const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
  })(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    ...(isFirstDay && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
  }));

const WeekPicker = (props) => {
  const {handleReportDateChange, reportDate} = props

    const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!reportDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(reportDate);
    const end = endOfWeek(reportDate);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const adjustedProps = (props) => {
    let newProps = {...props}
    newProps.inputProps.readOnly = true;
    return newProps
  }

  return(
    <Box style={{marginTop: '-15px', width: '150px', paddingLeft: '5px', paddingRight: '15px'}}>
      <DatePicker
        showToolbar={false} 
        label=" "
        onChange={handleReportDateChange}
        value={reportDate}
        autoOk={true}
        renderDay={renderWeekPickerDay}
        renderInput={(props) => <TextField {...adjustedProps(props)} variant='standard'/>}
        inputFormat="'Week of 'dd MMM"

      />
    </Box>
  )
}


export default WeekPicker;

import React, {
  useLayoutEffect,
  useState,
  useEffect,
} from 'react';
import {
} from 'react-router-dom';

import * as d3 from 'd3'; 
const SimSummaryGraph = (props) => {
  const [sectionWidth, setSectionWidth] = useState();
  const {simData} = props;
  const simSummaryGraph = React.createRef();
  const sectionRef = React.createRef();

  useEffect(() => {
    if (sectionRef.current && sectionWidth!== sectionRef.current.offsetWidth) {
      setSectionWidth(sectionRef.current.offsetWidth)
    }
  }, [sectionRef, sectionWidth])
useLayoutEffect(() => {
    const renderSimSummaryGraph = () => {

      const svg = d3.select(simSummaryGraph.current);
      var margin = {top: 30, right: 180, bottom: 30, left: 50}
      const height = 600 - margin.top - margin.bottom;
      const width = sectionWidth - margin.left - margin.right;

      const allDatapointsY = simData
        .map((sim) => sim.sim_card.balances.map((bal) => bal.balance))
        .flat()
      const maxY = d3.max(allDatapointsY, (d) => d)
      const yScale = d3.scaleLinear()
        .domain([0,maxY])
        .range([height,0])

      const allDatapointsX = simData
        .map((sim) => sim.sim_card.balances.map((bal) => new Date(bal.created_at)))
        .flat()
      const minX = new Date(d3.min(allDatapointsX))
      const maxX = new Date(d3.max(allDatapointsX))
      const xScale = d3.scaleTime()
        .domain([minX,maxX])
        .range([0,width])

      let zScale = d3.scaleOrdinal(d3.schemeCategory10)
        .domain(simData.map((sim) => sim.sim_card.phNumber))

      const line = d3.line()
        .curve(d3.curveBasis)
        .x((d) =>xScale(new Date(d.created_at)))
        .y((d) => yScale(d.balance));

      if(svg.nodes()[0].childNodes.length === 0){

        svg
          .attr("class", "simGraph")
          .attr("height", height+margin.top+margin.bottom)
          .attr("width", width + margin.left + margin.right)

        const update = svg
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        update.append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + (height) + ")")
          .call(d3.axisBottom(xScale))

        const yaxis = update.append("g")
          .attr("class", "axis axis--y")
          .call(d3.axisLeft(yScale))
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", "0.71em")
          .attr("fill", "#000")
          .text("Balance, KB");

        const sim = update
          .append("g")
          .attr("class", "sim")
          .selectAll('path')
          .data(simData)

        sim
          .enter()
          .append("path")
          .merge(sim)
          .attr("class", "line")
          .attr("d", (d) => line(d.sim_card.balances))
          .attr('fill','rgba(0,0,0,0)')
          .style("stroke", (d) => zScale(d.sim_card.phNumber));

        const label = update 
          .selectAll('g.sim')
          .selectAll('text')
          .data(simData)

        label
          .enter()
          .append("text")
          .merge(label)
          .datum((d) => {return {phNumber: d.sim_card.phNumber, projectName: d.name, balance: d.sim_card.balances[d.sim_card.balances.length-1]}})
          .attr("transform", (d) => {
            return "translate(" + xScale(new Date(d.balance.created_at)) + "," + yScale(d.balance.balance) + ")"
          })
          .attr("x", 3)
          .attr("dy", "0.35em")
          .style("font", "10px roboto")
          .text((d) => d.phNumber+", "+d.balance.balance+", "+d.projectName)

        sim.exit().remove()
        label.exit().remove()
        yaxis.exit().remove()

      } else {

        const sim = svg
          .selectAll("g > g.sim")
          .selectAll('path')
          .data(simData)

        sim
          .enter()
          .append("path")
          .merge(sim)
          .attr("class", "line")
          .attr("d", (d) => line(d.sim_card.balances))
          .attr('fill','rgba(0,0,0,0)')
          .style("stroke", (d) => zScale(d.sim_card.phNumber));

        const label = svg
          .selectAll('g > g.sim')
          .selectAll('text')
          .data(simData)

        label
          .enter()
          .append("text")
          .merge(label)
          .datum((d) => {return {phNumber: d.sim_card.phNumber, projectName: d.name, balance: d.sim_card.balances[d.sim_card.balances.length-1]}})
          .attr("transform", (d) => {
            return "translate(" + xScale(new Date(d.balance.created_at)) + "," + yScale(d.balance.balance) + ")"
          })
          .attr("x", 3)
          .attr("dy", "0.35em")
          .style("font", "10px roboto")
          .text((d) => d.phNumber+", "+d.balance.balance+", "+d.projectName)

        const yaxis = svg.select("g > g.axis--y")

        yaxis.call(d3.axisLeft(yScale))
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", "0.71em")
          .attr("fill", "#000")
          .text("Balance, KB");

        sim.exit().remove()
        label.exit().remove()
        yaxis.exit().remove()

      }

    }
    if (simSummaryGraph.current && simData){
      renderSimSummaryGraph();
    }
  }, [sectionWidth, simData, simSummaryGraph])

  const renderSimSummaryGraph = () => {
    if (sectionWidth >0) {
      return (

        <svg ref={simSummaryGraph}/>
      )
    }
  }
  return(
    <div ref={sectionRef} className='simSummaryGraph'>
      {renderSimSummaryGraph()}
    </div>
  )
}

export default SimSummaryGraph;

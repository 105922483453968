import React, {
  useLayoutEffect,
  useState,
  useEffect,
} from 'react';
import {
} from 'react-router-dom';
import {format} from 'date-fns'

import * as d3 from 'd3'; 
const ProjectListSummaryGraph = (props) => {
  const {projectListData, colors} = props;
  const [sectionWidth, setSectionWidth] = useState();
  const projectListSummaryGraph = React.createRef();
  const sectionRef = React.createRef();

  useEffect(() => {
  if (sectionRef.current && sectionWidth!== sectionRef.current.offsetWidth) {
    setSectionWidth(sectionRef.current.offsetWidth)
  }
  }, [sectionRef, sectionWidth])

  useLayoutEffect(() => {
    const renderProjectListSummaryGraph = () => {

      const svg = d3.select(projectListSummaryGraph.current);

      const checkinColor = colors.checkIn; 
      if(svg.nodes()[0].childNodes.length === 0)
      { 
        const height = 100;
        const margin = {top: 10, right: 10, bottom: 10, left: 10}
        const width = sectionWidth - margin.left - margin.right;
        const maxBarWidth = 80;
        const barWidth = d3.min([maxBarWidth, (width - margin.left - margin.right) / (projectListData.length * 1.5)])
        const spacing = barWidth * 0.5;
        const maxHeight = d3.max(projectListData, (d) => d.count/1)
        const xAxisHeight = 15
        const labelHeight = 15
        const isXSmallGraph = (barWidth * 1.5) < 20

        const yScale = d3.scaleLinear()
          .domain([0,maxHeight])
          .range([0,height - margin.top - margin.bottom - xAxisHeight - labelHeight])

        const update = svg
          .attr('height', height)
          .attr('width', width)
          .append('g')
          .selectAll('rect')
          .data(projectListData);

        update.enter()
          .append('rect')
          .attr('x', (d, i) => (i * (spacing + barWidth) + margin.left + spacing / 2))
          .attr('y', (d,i) => height - yScale(d.count) - margin.bottom - xAxisHeight)
          .attr('width', barWidth)
          .attr('height', (d,i) => yScale(d.count))
          .attr('fill',checkinColor)

        update.enter()
          .append('text')
          .attr('x', (d,i) => (i * (spacing + barWidth)+ margin.left + ((spacing + barWidth) / 2)))
          .attr('y', (d,i) => height - yScale(d.count) - margin.bottom - 5 - xAxisHeight)
          .style("text-anchor", "middle")
          .style('font-size', '12px')
          .style('font-style','italic')
          .style('fill',checkinColor)
          .text((d,i)=> (!isXSmallGraph && (d.count > 0)) ? d.count : '' )

        update.enter()
          .append('text')
          .attr('x', (d,i) => (i * (spacing + barWidth)+ margin.left + ((spacing + barWidth) / 2)))
          .attr('y',height - margin.bottom)
          .style("text-anchor", "middle")
          .style('font-size', '14px')
          .style('fill',checkinColor)
          .text((d,i)=> !isXSmallGraph ? format(new Date(d.date), 'd') : '')

        const x = d3.scaleBand()
          .domain(d3.range(projectListData.length))
          .range([margin.left, (width - margin.right)])

        const xAxis = g => g
          .attr("transform", `translate(0,${height - margin.bottom - xAxisHeight})`)
          .call(d3
            .axisBottom(x)
            .tickFormat("")
            .tickSize(0)
            .tickSizeOuter(0)
          )

        let axis = svg.append('g')
          .call(xAxis);

        axis.selectAll("path")
          .style("stroke", 'gray');
      }
    }

    if (projectListSummaryGraph.current && projectListData){
      renderProjectListSummaryGraph();
    }

  }, [colors.checkIn, sectionWidth, projectListData, projectListSummaryGraph])

  const renderSummaryGraph = () => {
    if (sectionWidth > 0) {
      return (
        <svg ref={projectListSummaryGraph}/>
      )
    }
  }
  return(
    <div ref={sectionRef} className='projectListSummaryGraph' style={{padding: '10px'}}>
      {renderSummaryGraph()}
    </div>
  )
}

export default ProjectListSummaryGraph;

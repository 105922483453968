import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { useAuth, MainAppApi  } from '_services'
import { setCredentials } from '_reducers'
import { LoadingScreen } from '_templates'

const { useGetProfileQuery } = MainAppApi

const PrivateRoute = () => {
  const [skip, setSkip] = useState(true)
  const { profile } = useAuth() 
  const location = useLocation()
  const { data: profileData, isLoading } = useGetProfileQuery({}, skip)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const redirectToLogin = (
    <Navigate
      to= '/login'
      state= {{ from: location }}
    />
  )

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!profile.userType) {
    const localProfile = JSON.parse(localStorage.getItem('profile'))

    if (localProfile) {
      dispatch(setCredentials({ token: localProfile.token, userType: localProfile.userType }))

    } else {
      const authToken = searchParams.get('auth_token')
      const mainAppSource = !!authToken

      if (!mainAppSource) return redirectToLogin

      if (skip) {
        dispatch(setCredentials({ token: authToken }))
        setSkip(false)
      }

      if (profileData) {
        dispatch(setCredentials({ token: authToken, userType: 'mainApp' }))
        setSkip(true)
      }
    }
  }

  return profile.userType ? (
    <Outlet /> 
  ) : redirectToLogin
}

export default PrivateRoute

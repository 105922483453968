export const idSplitter = (id) => {
  const splitSize = 3;
  const idString = id.toString()
  const preZerosCount = 9 - idString.length;
  const preZeroText = "0".repeat(preZerosCount);
  const fullStringArray = preZeroText.concat(idString).split('');

  let result = fullStringArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/splitSize)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])

  result = result.join('/').replace(/,/g,'');
  return result
}

export default idSplitter;

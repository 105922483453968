import React from 'react';
import {Box} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(theme => ({
  loadingBox: {
    height: window.innerHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))


const LoadingScreen = () => {
  const classes = styles();
    return(
      <Box className={classes.loadingBox}>
        <CircularProgress size={100} disableShrink/>
      </Box>
    )
}


export default LoadingScreen 

import React from 'react';

import {FormControlLabel, Checkbox, TextField, Button, Card, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(theme => ({
  submitButton: {
    margin: '10px',
  },
  passcodeInput: {
    alignItems: 'center',
    justifyContent: 'left',
    margin: '10px',
  }, passcodeInfoText: {
    maxWidth: '500px',
    marginRight: 'auto',
    marginLeft: 'auto',
    color: theme.palette.secondary.main,
    fontStyle: 'italic',
  },
  passcodeCard: {
    maxWidth: '500px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'center',
  },
  passcodeText: {
    color: theme.palette.secondary.main,
    padding: '15px',
    textAlign: 'center',
  },
}))

const LoginForm = (props) => {
  const {formErrorText, passcode, setPasscode, rememberMe, setRememberMe, submitPasscode} = props;
  const classes = styles();

  const handleChange = (text) => {
    setPasscode(text.target.value);
  }

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  }

  return (
    <div>
      <Typography 
        variant='body2' 
        align= 'center'
        paragraph={true}
        className={classes.passcodeInfoText}
      >
        To ensure data security for clients, we have added temporary passcodes. These dashboards will soon be integrated into the common LinkNBuild login system.
      </Typography>
      <Typography 
        variant='body2' 
        align= 'center'
        paragraph={true}
        className={classes.passcodeInfoText}
      >
        If you haven't received an email with your passcode, please email info@linknbuild.com or call 082 752 0976.
      </Typography>
      <Typography 
        variant='body2' 
        align= 'center'
        paragraph={true}
        className={classes.passcodeInfoText}
      >
        We apologise for any inconvenience caused.
      </Typography>
      <Card raised={true} className={classes.passcodeCard}>
        <Typography variant='h5' className={classes.passcodeText}>Enter passcode below:</Typography>
        <TextField 
          onKeyDown={(e) => (e.code === 'Enter') ? submitPasscode() : null}
          error={formErrorText !== ''} 
          helperText={formErrorText} 
          id="passcode" 
          label="Passcode" 
          variant="outlined" 
          value = {passcode} 
          onChange={handleChange}
          className={classes.passcodeInput}
          autoFocus
        />
        <FormControlLabel label="Don't ask again" control={<Checkbox checked={rememberMe} onChange={toggleRememberMe}/>}/>
        <Button 
          variant='contained' 
          color='primary'
          className={classes.submitButton}
          onClick={() => submitPasscode()}
        >
          Submit
        </Button>
      </Card>
    </div>
  )
}

export default LoginForm;

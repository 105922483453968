import React, {
  useState 
} from 'react' 

import {useNavigate, useLocation} from 'react-router-dom'

import Paper from '@mui/material/Paper'
import {Box, Card, Grid} from '@mui/material'

import {addDays} from 'date-fns'


import {Layout} from '_templates'
import {EmployeeTnATable, DateSlider} from '_molecules'
import {ReportsApi} from '_services'

const EmployeeTimes = (props) => {
  const [startDate, setStartDate] = useState(addDays(new Date(), -14))
  const [endDate, setEndDate] = useState(new Date())

  const navigate = useNavigate()
  const location = useLocation()

  const {projectIds, companyId, companyNameText} = props

  const {useGetEmployeeTimesQuery} = ReportsApi

  const {data: employeeData, isFetching: isLoading, error}= useGetEmployeeTimesQuery({companyId, projectIds, companyNameText, startDate, endDate})

  const maxDate = new Date()

  if (error && error.originalStatus === 401){
    navigate('/login', {replace: true, state: {from: location}})
  }
  if (employeeData && startDate === null && endDate === null) {
    setStartDate(new Date(employeeData.start_date))
    setEndDate(new Date(employeeData.end_date))
  }

  return(
    <Layout
      title='AB Subcontractors T&A'
      isLoading={isLoading}
    >
      <Paper sx={{ width: '100%' }}>
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card raised={true}>
              <Box height={100} >
                {startDate && endDate &&
                <DateSlider
                  startDate={startDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  maxDate={maxDate}
                />
                }
              </Box>
            </Card>
          </Grid>
        { !error &&
        <EmployeeTnATable
          employees={employeeData?.employees}
          startDate={employeeData?.start_date}
          endDate={employeeData?.end_date}
        />
        }
      </Grid>
      </Paper>
    </Layout>
  )
}

export default EmployeeTimes 

import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'

import {
  isSameDay,
  format,
  intervalToDuration,
  formatDuration,
  differenceInMinutes,
  differenceInCalendarDays
} from 'date-fns'

import AttentionIcon from '@mui/icons-material/Report'
import InfoIcon from '@mui/icons-material/Info'
import TickIcon from '@mui/icons-material/CheckCircle'
import Chip from '@mui/material/Chip';
import { Card, Grid, Box, Typography} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import _ from 'lodash'

import {Layout} from '_templates'
import {ReportsApi, sortByParam} from '_services'

const PlantAnalysis = (props) => {
  const navigate = useNavigate();
  const location = useLocation()

  let {projectId} = useParams()
  const {
    useGetProjectCompanyQuery,
    useGetPlantActivityAnalysisQuery,
    useGetFirstLastPlantAnalysisQuery,
    useGetProjectDelayReasonsQuery,
    useGetProjectLocationsQuery,
    useGetProjectMaterialsQuery,
  } = ReportsApi

  const {
    data: companyData,
    isFetching: isLoadingCompanyData,
    error: companyDataError
  } = useGetProjectCompanyQuery(projectId)

  const {
    data: plantActivityAnalysisData,
    isFetching: isLoadingPlantActivityAnalysisData,
    error: plantActivityAnalysisDataError
  } = useGetPlantActivityAnalysisQuery({projectId})

  const {
    data: firstLastPlantAnalysisData,
    isFetching: isLoadingFirstLastPlantAnalysisData,
    error: firstLastPlantAnalysisDataError
  } = useGetFirstLastPlantAnalysisQuery({projectId})

  const {
    data: projectDelayReasonsData,
    isFetching: isLoadingProjectDelayReasonsData,
    error: projectDelayReasonsDataError
  } = useGetProjectDelayReasonsQuery(projectId)

  const {
    data: projectLocationsData,
    isFetching: isLoadingProjectLocationsData,
    error: projectLocationsDataError
  } = useGetProjectLocationsQuery(projectId)

  const {
    data: projectMaterialsData,
    isFetching: isLoadingProjectMaterialsData,
    error: projectMaterialsDataError
  } = useGetProjectMaterialsQuery(projectId)

  const errors = [
    projectMaterialsDataError,
    projectLocationsDataError,
    projectDelayReasonsDataError,
    firstLastPlantAnalysisDataError,
    plantActivityAnalysisData
  ]

  const authErrors = errors.filter((err) => err && err.originalStatus === 401 )
  const hasAuthError = authErrors.length > 0

  if (hasAuthError){
    navigate('/login', {replace: true, state: {from: location}})
  }

  const isLoading =
    isLoadingCompanyData ||
    isLoadingProjectMaterialsData ||
    isLoadingProjectLocationsData ||
    isLoadingProjectDelayReasonsData ||
    isLoadingFirstLastPlantAnalysisData ||
    isLoadingPlantActivityAnalysisData

  const renderPlantHeadings = (plants) => {
    const renderedPlants = plants.map((plant) => <TableCell key={plant.plant_id} colSpan={2} align='center'>{plant.plant_name}</TableCell>)
    const plantHeaders = plants.map((plant) => <><TableCell align='center'>First</TableCell><TableCell align='center'>Last</TableCell></>)
      return(
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {renderedPlants}
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              {plantHeaders}
            </TableRow>
          </TableHead>
      )
  }

  const getPlantIndex = (plants, plantId) => {
    return plants.map((p) => p.plant_id).indexOf(plantId)
  }

  const renderPlantDataCells = (firstLastData) => {
    let rows = []
    firstLastData.forEach((plant) => {
      plant.first_last_deliveries.forEach((dateData) => {
        let  existingRow = _.find(rows, {date: dateData.delivery_date})
        if (existingRow){
          let plantIndex = getPlantIndex(firstLastData, plant.plant_id)
          let rowSkip = existingRow.data.length  < plantIndex
          while (rowSkip) {
            existingRow.data.push(null, null)
            plantIndex = getPlantIndex(firstLastData, plant.plant_id)
            rowSkip = existingRow.data.length  < plantIndex
          }
          existingRow.data.push(dateData.first_delivery)
          existingRow.data.push(dateData.last_delivery)
        } else {
          rows.push({date: dateData.delivery_date, data: [dateData.first_delivery, dateData.last_delivery]})
        }
      })
    })

    const renderRows = rows.map((row, rowI) => {
      return(
        <TableRow key = {`row-${row.date}`}>
          <TableCell align='right'>{row.date}</TableCell>
          {row.data.map((d, colI) => <TableCell key={`datacell-${rowI}-${colI}`}align='center'>{d}</TableCell>)}
        </TableRow>
      )
    })

    return(
      <TableBody>
        {renderRows}
      </TableBody>
      )
  }

  const FirstLastAnalysis = ({firstLastData}) => {
    return(
      <TableContainer component={Paper} sx={{paddingTop: 2}}>
        <Table sx={{minWidth: 650}} size='small'>
            {renderPlantHeadings(firstLastData)}
          {renderPlantDataCells(firstLastData)}
        </Table>
      </TableContainer>
    )

  }

  return(
    <Layout
      title='Plant Activity Analysis'
      isLoading={isLoading}
      company={companyData}
      maxWidth='false'
      productType='lnb'
    >
      {!isLoading && !hasAuthError &&
      <>
        <Grid container spacing={2}>
          <FirstLastAnalysis firstLastData={firstLastPlantAnalysisData} />
        </Grid>
      </>
      }
    </Layout>
  )
}


export default PlantAnalysis

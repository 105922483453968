import React, {
  useLayoutEffect,
} from 'react';
import {
} from 'react-router-dom';

import * as d3 from 'd3';

const LiftsDoughnutGraph = (props) => {
  const {liftsDoughnutGraphData} = props;
  const liftsDoughnutGraph = React.createRef();

  useLayoutEffect(() => {
    const renderLiftsDoughnutGraph = () => {
      const svg = d3.select(liftsDoughnutGraph.current)

      if(svg._groups[0][0].childNodes.length === 0){

        const height = 60;
        const width = 60;

        const update = svg
          .attr('height', height)
          .attr('width', width)
          .append('g')
          .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')

        let pie = d3.pie()
          .value((d) => d.value)
          .sort(null)
          

        let dataReady = pie(liftsDoughnutGraphData.data)

        update.selectAll('rings')
          .data(dataReady)
          .enter()
          .append('path')
          .attr('d', d3.arc()
            .innerRadius(25)
            .outerRadius(30))
          .attr('fill', (d) => d.data.color)
          .style('opacity',0.7)

        update
          .append('text')
          .attr('x', 0)
          .attr('y', 6)
          .attr('dy',0)
          .attr('dx',0)
          .style("text-anchor", "middle")
          .style('font-size', '18px')
          .style('font-family', 'roboto')
          .text(liftsDoughnutGraphData.centerText)
      }
    }

    if (liftsDoughnutGraph.current && liftsDoughnutGraphData.length !== 0){
      renderLiftsDoughnutGraph();
    }
  }, [liftsDoughnutGraphData, liftsDoughnutGraph])

  return(
    <div className='liftsDoughnutGraph'>
      <svg ref={liftsDoughnutGraph}/>
    </div>
  )
}

export default LiftsDoughnutGraph;

import React, {
  useState,
  createRef,
  useEffect,
} from 'react'
import {
} from 'react-router-dom' 
import {format} from 'date-fns' 
import {Typography, Box} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BlockIcon from '@mui/icons-material/Block'
import AccessibleIcon from '@mui/icons-material/CheckCircleOutline'
import {getAccessDetails} from '_services'

const styles = makeStyles(theme => ({
  thumbNail: {
    width: '50px',
    height: '50px',
    borderRadius: '50px',
    display: 'block',
  },
  personName: {
    width: '100%',
    overflow: 'hidden',
    fontSize: '0.7em',
    textAlign: 'center'
  },
  companySection: {
    borderRadius: '5px',
  },
  companyHeader: {
    cursor: 'pointer',
  },
  siteAttendanceDetails: {
    padding: '5px',
  },
  companyNameSection: {
    display: 'inline-block',
    width: '160px',
    borderRadius: '5px',
  },
  companyName: {
    display: 'inline-block',
    fontSize: '1em',
    margin: '0',
    fontWeight: '500',
  },
  labour: {
    cursor: 'pointer',
    display: 'inline-block',
    width: '50px',
    height: '40px',
    margin: '3px',
  },
  expandIcon: {
    height: '20px',
    float: 'right',
  },
  attendanceBar: {
    height: '12px',
    backgroundColor: theme.palette.secondary.main,
  },
  attendanceBarHighlight: {
    marginTop: '-5px',
    height: '5px',
    backgroundColor: theme.palette.red.main,
  },
  attendanceBarOnSiteCount: {
    marginTop: '-12px',
    height: '5px',
    backgroundColor: theme.palette.green.main,
  },
  attendanceBarSection: {
    display: 'inline-block',
  },
  attendanceBarText: {
    margin: '0',
    fontSize: '1.0em',
    display: 'inline-block',
    padding: '5px',
  },
  attendanceBarHighlightText: {
    marginLeft: '-8px',
    color: theme.palette.red.main,
    fontSize: '0.85em',
  },
  attendanceBarOnSiteCountText: {
    marginLeft: '-8px',
    color: theme.palette.green.main,
    fontSize: '0.85em',
  },
  tooltipText: {
    margin: '0',
    paddingLeft: '2',
    padding: '0',
  },
  accessIcons: {
    height: '15px',
    width: '15px',
    marginVertical: '-15px',
    position: 'relative',
    top: '-69px',
    right: '-35px',
    color: '#FFF',
    borderRadius: '15px'
  },
  accessDeniedText: {
    color: theme.palette.red.main,
  },
  accessDenied: {
    backgroundColor: theme.palette.red.main,
  },
  accessGranted: {
    backgroundColor: theme.palette.green.main,
  },
}))

const combineDateWithTimestring = (datetime, timeString) => {
  const date = new Date(datetime);
  const [hours, minutes] = timeString.split(':');

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

const truncateString = (string, length) => {
  if (string.length > length) {
    string = `${string.slice(0,length-1)}...`
  }
  return string
}

const AttendanceBar = (props) => {
  const {value, graphWidth,  highlightValue, onSiteCountValue, maxValue} = props
  const classes = styles()
  const mainWidth = graphWidth > 0 ? value / maxValue * (graphWidth - 251) : 0
  const highlightWidth = graphWidth > 0 ? highlightValue / maxValue * (graphWidth - 251) : 0
  const onSiteCountWidth = graphWidth > 0 ? onSiteCountValue / maxValue * (graphWidth - 251) : 0
  return(
    <>
      <Box className = {classes.attendanceBarSection}>
        <Box style={{width: mainWidth}} className = {classes.attendanceBar} />
        <Box style={{width: highlightWidth}} className = {classes.attendanceBarHighlight} />
        <Box style={{width: onSiteCountWidth}} className = {classes.attendanceBarOnSiteCount} />
      </Box>
      <Typography variant='h6' className={classes.attendanceBarText}>{value}</Typography>
      <Typography variant='h6' className={[classes.attendanceBarText, classes.attendanceBarHighlightText].join(' ')}>{highlightValue > 0 ? `(${highlightValue})`: ''}</Typography>
      <Typography variant='h6' className={[classes.attendanceBarText, classes.attendanceBarOnSiteCountText].join(' ')}>{onSiteCountValue > 0 ? `(${onSiteCountValue})`: ''}</Typography>
    </>
  )
}

const SiteAttendanceDetails = (props) => {
  const {selectedTime, filtered, personnelData, setEmployeeProfile, requirements} = props
  const [expandedCompanies, setExpandedCompanies] = useState([])
  const [graphWidth, setGraphWidth] = useState(0)
  const [maxCount, setMaxCount] = useState(0)
  
  const selectedTimeVal = selectedTime ? combineDateWithTimestring(personnelData.personnel[0].personnel[0].employeeActivityPeriods[0].start_time, selectedTime) : null

  const classes = styles()

  const detailsRef = createRef()

  const renderExpandedData = (companyLabour, showIndividuals, companyName) => {
    const renderIndividuals = () =>{
      if (showIndividuals)  {
        return companyLabour.map((person) => {
          const uri = `https://lnb-ai-facial-rec.s3.eu-west-2.amazonaws.com/saved-faces/thumb_${person.id}.jpg`
          let name = person.name.toUpperCase().slice(0,`${person.name} `.indexOf(" "))
          name = truncateString(name, 7)
          const nameText = () => (<p className={classes.tooltipText}>{person.name} {person.surname}</p>)
          const companyText = () => person.companyName ? (<p className={classes.tooltipText}>{person.companyName}</p>) : "" 
          const positionText = () => person.position ? (<p className={classes.tooltipText}>{person.position}</p>) : "" 
          const showEmployeeProfile = () => setEmployeeProfile({...person, companyName: companyName})
          const timesText = () => {
            return person.employeeActivityPeriods.map((eap) => {
              const finishTimeText = eap.finish_time ? `-${format(new Date(eap.finish_time), "HH:mm")}` : ""
              return (<p className={classes.tooltipText}>{format((new Date(eap.start_time)), "HH:mm")}{finishTimeText}</p>)
            })
          }

          const { accessDetails } = person

          const accessText = () => {
            if (accessDetails.access === 'granted') {
              return <p className={classes.tooltipText}>Access granted</p>
            }
            let deniedText = [<p className={[classes.tooltipText, classes.accessDeniedText].join(' ')}>Access denied:</p>]
            deniedText.push(...accessDetails.failures.map((f) => <p className={classes.tooltipText}>{f.requirement} {f.message}</p>))
            return deniedText
          }

          const renderAccessibilityIcon = () => {

              if (accessDetails.access === 'denied'){
                const accessClass = classes.accessDenied;
                return <BlockIcon className={[classes.accessIcons, accessClass].join(' ')}/>
              } else {
                const accessClass = classes.accessGranted
                return <AccessibleIcon className={[classes.accessIcons, accessClass].join(' ')}/>
              } }

          const profileTooltipText = () => (
            <Box>
              {nameText()} 
              {companyText()}
              {positionText()}
              {timesText()}
              <p />
              {accessText()}
            </Box>
          )

          return(
            <Tooltip title = {profileTooltipText()} >
              <Box className={classes.labour} onClick={showEmployeeProfile}>
                <img className={classes.thumbNail} src={uri} alt={`profile_photo_${person.id}`} />
                <div className={classes.personName}>{truncateString(name,7)}</div>
                {renderAccessibilityIcon()}
              </Box>
            </Tooltip>
          ) 
        })
      } else return null
    }
    return (
      <Box className= {classes.personnelList} >
        {renderIndividuals()}
      </Box>
    )
  }

  const toggleExpandedCompanies = (name) => {
    if (expandedCompanies.indexOf(name) === -1) {
      setExpandedCompanies([...expandedCompanies, name])
    } else{
      setExpandedCompanies(expandedCompanies.filter((companyName) => companyName !== name))
    }
  }

  useEffect(() => {
  if (detailsRef.current && graphWidth !== detailsRef.current.offsetWidth) {
    setGraphWidth(detailsRef.current.offsetWidth)
  }
  }, [detailsRef, graphWidth])

  useEffect(() => {
    if (personnelData && maxCount !== personnelData.personnel[0]?.personnel.length)
      setMaxCount(personnelData.personnel[0].personnel.length)
  }, [maxCount, personnelData])

  const renderCompanyData = () => {
    return personnelData.personnel.map((company, index) => {
      const showIndividuals = (expandedCompanies.indexOf(company.companyName) !== -1)
      let personnel = company.personnel

      if(filtered.length > 0){
        personnel = company.personnel.filter((e) => filtered.map((f) => f.position).indexOf(e.position) !== -1)
      }

      personnel = personnel.map((p) => {
        const accessDetails = getAccessDetails(requirements, p.completionMarks)
        return {accessDetails, ...p}
      })
      if(personnel.length > 0) {
        const failedAccessCount = personnel.filter((p) => p.accessDetails.access === 'denied').length
        const onSiteCountValue = selectedTimeVal ? personnel.filter((p) => {
          return p.employeeActivityPeriods.filter((eap) => {
            const startedBefore = eap.start_time && new Date(eap.start_time).valueOf() < selectedTimeVal.valueOf()
            const finishedAfter = !eap.finish_time || new Date(eap.finish_time).valueOf() > selectedTimeVal.valueOf()
            return startedBefore && finishedAfter
          }).length > 0

        }).length : 0
        return (
          <Box key={index} className= {classes.companySection} ref={detailsRef}>
            <Box className = {classes.companyHeader} onClick={() => toggleExpandedCompanies(company.companyName)}>
              <Box className= {classes.companyNameSection}>
                <Tooltip title={company.companyName}>
                  <Typography variant='h6' className={classes.companyName}>{truncateString(company.companyName,15)}</Typography>
                </Tooltip>
                <KeyboardArrowDownIcon className={classes.expandIcon}/>
              </Box>
              <AttendanceBar value={personnel.length} graphWidth={graphWidth} maxValue={maxCount} highlightValue={failedAccessCount} onSiteCountValue={onSiteCountValue}/>
            </Box>
            {renderExpandedData(personnel, showIndividuals, company.companyName)}
          </Box>
        )
      } else return null
    })
  }

  return(
    <div className={classes.siteAttendanceDetails}>
      {renderCompanyData()}
    </div>
  )
}

export default SiteAttendanceDetails

import {useState, useEffect} from 'react'
import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'

import {
  isSameDay,
  format,
  intervalToDuration,
  formatDuration,
  differenceInMinutes,
} from 'date-fns'

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import AttentionIcon from '@mui/icons-material/Report'
import InfoIcon from '@mui/icons-material/Info'
import TickIcon from '@mui/icons-material/CheckCircle'
import Chip from '@mui/material/Chip';
import { Card, Grid, Box, Typography} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {LiftsDoughnutGraph} from '_organisms'
import {Layout} from '_templates'
import {ReportsApi, sortByParam} from '_services'

const getInitialsFromFullName = (fullName) => {
  const splitName = fullName.split(' ')
  const nameCount = splitName.length
  const initials = splitName[0][0]
  if (nameCount > 1) {
    return initials + splitName[nameCount - 1][0]
  }
  return initials 
}

const StatusBadge = ({status, text}) => {
  let icon, color

  switch (status) {
    case 'warning':
      icon = <AttentionIcon />
      color = 'warning'
      break
    case 'critical':
      icon = <AttentionIcon />
      color = 'error'
      break
    case 'success':
      icon = <TickIcon />
      color = 'success'
      break
    default:
      icon = <InfoIcon />
      color = 'info'
  }

  return (
      <Chip
        icon={icon}
        color={color}
        label={text}
        size='small'
      />
  )
}

const getCustomer = (activity, company, status) => {
  let companyName, fullName, initials
  let plannedActivity = activity

  if (status && !status.isPlanned) return null

  if (activity.planned_plant_activity_id) {
    plannedActivity = activity.planned_plant_activity
  }

  if (plannedActivity.customer_type === 'User') {
    const user = plannedActivity.user_customer
    const {name, surname} = user.employee
    fullName = name + ' ' + surname
    initials = getInitialsFromFullName(fullName)
    companyName = user.employee.company_name || company.name
  } else if (plannedActivity.public_user_customer) {
    const user = plannedActivity.public_user_customer
    fullName = user.full_name
    initials = getInitialsFromFullName(fullName)
    companyName = user.company_name
  }

  return {companyName, fullName, initials}
}

const getFormattedTimeText = (timestamp) => {
  const timestampDate = new Date(timestamp)
  const isToday = isSameDay(timestampDate, new Date())
  if (isToday) {
    return format(new Date(timestampDate), 'HH:mm')
  } else {
    return format(new Date(timestampDate), 'HH:mm ccc d MMM')
  }
}

const getFormattedDurationText = (start, end, style) => {

  const curtailDurationDetails = (duration) => {
    if (duration.years > 0) {
      duration = {
        years: duration.years,
        months: duration.months,
      }
    } else if (duration.months > 0) {
      duration = {
        months: duration.months,
        days: duration.days,
      }
    } else if (duration.days > 0) {
      duration = {
        days: duration.days,
        hours: duration.hours,
      }
    }
    return duration
  }

  let duration = intervalToDuration({start, end})
  duration = curtailDurationDetails(duration)

  let durationText = formatDuration(duration, {format: ['years','months','days', 'hours', 'minutes']})
  if (style === 'short') {
    durationText = durationText
      .replace(' minutes','m')
      .replace(' hours','h')
      .replace(' hour','h')
      .replace(' minute','h')
  } else {
    durationText = durationText
      .replace('minute', 'min')
      .replace('hour', 'hr')
  }
  return durationText
}


const getActivityStatus = (activity) => {

  const MINUTES_OFF_SCHEDULE_THRESHOLD = 10 
  const MINUTES_SINCE_LAST_LIFT_STALE_THRESHOLD = 60
  const isPlanned = activity.planned_plant_activity_id !== null
  const plannedStartTime = isPlanned && new Date(activity.planned_plant_activity.start_time)
  const plannedEndTime = isPlanned && new Date(activity.planned_plant_activity.end_time)
  const startedAt = new Date(activity.started_at)
  const now = new Date()
  const deliveriesCount = activity.plant_deliveries?.length()
  const lastActionTime = deliveriesCount ? new Date(activity.plant_deliveries[deliveriesCount - 1]) : new Date(activity.started_at)
  const isDelay = activity.delay_reason_id !== null

  const isLate = 
    isPlanned && 
    differenceInMinutes(now, plannedEndTime) > MINUTES_OFF_SCHEDULE_THRESHOLD

  const isEarly = 
    isPlanned && 
    differenceInMinutes(plannedStartTime, now) > MINUTES_OFF_SCHEDULE_THRESHOLD

  const startedLate = 
    isPlanned && 
    differenceInMinutes(startedAt, plannedStartTime) > MINUTES_OFF_SCHEDULE_THRESHOLD

  const startedEarly = 
    isPlanned && 
    differenceInMinutes(plannedStartTime, startedAt) > MINUTES_OFF_SCHEDULE_THRESHOLD

  let staleSince = false
  if (differenceInMinutes(now, lastActionTime) > MINUTES_SINCE_LAST_LIFT_STALE_THRESHOLD) {
    staleSince = getFormattedDurationText(lastActionTime, now, 'long')
  }

  return {
    isPlanned,
    isDelay,
    startedLate,
    startedEarly,
    isLate,
    isEarly,
    staleSince, 
  }
}

const getActivityBannerTimeText = (activity) => {
  const startTime = new Date(activity.start_time)
  const endTime = new Date(activity.end_time)
  const startTimeText = getFormattedTimeText(startTime)
  const endTimeText = getFormattedTimeText(endTime)
  const durationText = getFormattedDurationText(startTime, endTime, 'long')
  return `${startTimeText} to ${endTimeText} (${durationText})`
}

const ActivityBanner = ({activity, status, plannedActivity}) => {
  const theme = useTheme()
  let bannerText = ''
  let badgeConfig = {}
  if (status) {
    if (status.isDelay) {
      const now = new Date()
      const startTime = new Date(activity.started_at)
      const durationText = getFormattedDurationText(startTime, now, 'long')
      let plannedActivityText
      if (plannedActivity) {
        const plannedStartText = getFormattedTimeText(plannedActivity.start_time)
        const plannedEndText = getFormattedTimeText(plannedActivity.end_time)
        plannedActivityText = ', scheduled: ' + plannedActivity.material.name + ' to ' + plannedActivity.to_location.name + ' from ' + plannedStartText + ' to ' + plannedEndText
      } else {
        plannedActivityText = ', with no activity planned for now'
      }
      bannerText += durationText + plannedActivityText
      badgeConfig = {text: 'Delayed', status: 'warning'}
    } else {
      if (status.isPlanned) {
        if(status.isEarly || status.isLate) {
          if (plannedActivity){
            const plannedStartText = getFormattedTimeText(plannedActivity.start_time)
            const plannedEndText = getFormattedTimeText(plannedActivity.end_time)
            const plannedActivityText = plannedActivity.material.name + ' to ' + plannedActivity.to_location.name + ' from ' + plannedStartText + ' to ' + plannedEndText
            bannerText += 'Scheduled now: ' + plannedActivityText
          } else {
            bannerText += 'With no activity planned for now'
          }
          const badgeText = status.isEarly ? 'Early' : 'Late'
          badgeConfig = {text: badgeText, status: 'warning'}
        } else {
          badgeConfig = {text: 'On schedule', status: 'success'}
          bannerText += 'Planned: '
          const activityTimeText = getActivityBannerTimeText(activity.planned_plant_activity)
          bannerText += activityTimeText
        }
      } else {
        let plannedActivityText
        if (plannedActivity) {
          const plannedStartText = getFormattedTimeText(plannedActivity.start_time)
          const plannedEndText = getFormattedTimeText(plannedActivity.end_time)
          plannedActivityText = 'Scheduled now: ' + plannedActivity.material.name + ' to ' + plannedActivity.to_location.name + ' from ' + plannedStartText + ' to ' + plannedEndText
        } else {
          plannedActivityText = 'With no activity planned for now'
        }
        badgeConfig = {text: 'Unplanned', status: 'critical'}
        bannerText += plannedActivityText
      }
      if (status.staleSince) {
        badgeConfig = {text: 'Stale for ' + status.staleSince, status: 'critical'}
      }
    }
  } else {
    bannerText = getActivityBannerTimeText(activity)
  }
  return (
    <Grid 
      item 
      xs={4}
      sx={{padding: '5px', backgroundColor: theme.palette.grey['300']}}
    >
      {status && <StatusBadge {...badgeConfig} />}
      <Typography variant='body2' sx={{display: 'inline'}}> {bannerText} </Typography>
    </Grid>
  )
}

const ActivityDetailsSection = ({activity, company, currentActivityStatus, currentPlannedActivity}) => {

  const theme = useTheme()
  const isNow = !!currentActivityStatus
  const material = activity?.material?.name
  const to_location = activity?.to_location?.name
  const from_location = activity?.from_location?.name || 'anywhere'
  const {note} = activity

  const quantity = isNow ? activity.planned_plant_activity?.quantity : activity.quantity || 0
  const deliveryCount = activity.plant_deliveries_count

  let liftsText = isNow ? deliveryCount : quantity
  let liftsDoughnutGraphData = null

  if (currentActivityStatus?.isPlanned) {
    liftsText += '/' + quantity
    if (quantity && quantity > 0) {
      liftsDoughnutGraphData = {
        centerText: liftsText, 
        data: [
          {
            value: deliveryCount,
            color: theme.palette.success.dark,
          },
          {
            value: quantity - deliveryCount,
            color: theme.palette.grey['300'],
          },
        ]}
    }
  }

  const customer = getCustomer(activity, company, currentActivityStatus)
  const customerNameText = customer ? customer?.fullName : ''
  const companyNameText = customer ? `(${customer?.companyName})` : ''

  const renderCustomer = () => {
    const shouldShowCustomer = false
    return(
      <>
        {shouldShowCustomer && (
          <>
            <Box width='4px' sx={{marginRight: '-2px', marginLeft: '-2px', backgroundColor: theme.palette.grey['400']}} />
            <Grid 
              item 
              xs={5} 
              display='flex' 
              align='center' 
              justifyContent='center' 
            >
              <Grid container direction='column'>
                <Grid item xs={6}>
                  <Typography sx={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{customerNameText}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{companyNameText}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    )
  }

  const renderLiftsVisualization = () => {
    if (liftsDoughnutGraphData) {
      return <LiftsDoughnutGraph liftsDoughnutGraphData={liftsDoughnutGraphData} />
    } else {
      return <Typography variant='h5'>{liftsText}</Typography>
    }
  }

  const renderDetails = () => {
    if (currentActivityStatus?.isDelay) {
      return(
          <Grid 
            item 
            xs={12} 
            display='flex' 
            align='center' 
            justifyContent='center' 
          >
            <Typography>DELAYED: {activity.delay_reason.name}</Typography>
          </Grid>
      )
    } else {
      return(
        <Grid container direction='row'>
          <Grid 
            item 
            xs={9} 
            display='flex' 
            align='center' 
            justifyContent='center' 
          >
            <Grid container direction='column'>
              <Grid item xs='auto'>
                <Typography sx={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{material}</Typography>
              </Grid>
              <Grid item xs='auto'>
                <Typography sx={{overflow: 'hidden', whiteSpace: 'nowrap'}}>{from_location} to {to_location}</Typography>
              </Grid>
              {note && (
                <Grid item xs='auto'>
                  <Typography variant='body2' sx={{overflow: 'hidden', whiteSpace: 'nowrap'}}>note: {note}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {renderCustomer()}
          <Box width='3px' sx={{marginRight: '-2px', marginLeft: '-2px', backgroundColor: theme.palette.grey['400']}} />
          <Grid 
            item 
            xs={3} 
            display='flex' 
            align='center' 
            justifyContent='center' 
            padding='5px'
          >
            <Grid container direction='column' >
              <Grid item xs='auto' >
                {renderLiftsVisualization()}
              </Grid>
              <Grid item xs='auto' >
                <Typography variant='body2' sx={{overflow: 'hidden', whiteSpace: 'nowrap'}}>lifts</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Grid item xs={9}>
      <Grid container direction='column'>
        <ActivityBanner activity={activity} status={currentActivityStatus} plannedActivity={currentPlannedActivity}/>
        <Grid 
          item 
          xs={8}
        >
          {renderDetails()}
        </Grid>
      </Grid>
    </Grid>
  )

}

const ActivityTimecountSection = ({activity, currentActivityStatus, currentPlannedActivity}) => {

  const theme = useTheme()
  const isNow = !!currentActivityStatus


  let timeTextParagraphs = []
  let badges = []

  if (!isNow) {
    const startTime = activity.start_time
    const timeToNext = getFormattedDurationText(new Date(), new Date(startTime), 'short')

    timeTextParagraphs.push({text: "Starts in:", variant: 'body2'})
    timeTextParagraphs.push({text: timeToNext, variant: 'h5'})
  } else {
    const {isLate, isEarly, startedLate, startedEarly, isPlanned} = currentActivityStatus


    if (isPlanned) {
      const plannedFinish = new Date(activity.planned_plant_activity.end_time)
      const plannedStart = new Date(activity.planned_plant_activity.start_time)
      const plannedFinishText = getFormattedTimeText(plannedFinish)
      const plannedStartText = getFormattedTimeText(plannedStart)
      const offScheduleText = 'Scheduled ' + plannedStartText + ' to ' + plannedFinishText

      let badgeTimeText = ''
      let badgeStatus = 'warning'

      if (startedEarly) {
        badgeTimeText += 'Early'
      } else if (startedLate) {
        badgeTimeText += 'Late'
      } else {
        badgeTimeText += 'On time'
        badgeStatus = 'success'
      }

      badgeTimeText += ' start'

      const startedAt = getFormattedTimeText(activity.started_at)
      badgeTimeText += ` (${startedAt})`
      badges.push({text: badgeTimeText, status: badgeStatus})

      if (isLate) {
        const timer = getFormattedDurationText(plannedFinish, new Date(), 'short')
        timeTextParagraphs.push({text: timer, variant: 'h5'})
        timeTextParagraphs.push({text: 'past planned finish', variant: 'body2'})
      } else if (isEarly) {
        const timer = getFormattedDurationText(new Date(), plannedStart, 'short')
        timeTextParagraphs.push({text: 'Due to start in',variant: 'body2'})
        timeTextParagraphs.push({text: timer, variant: 'h5'})
      } else {
        const timer = getFormattedDurationText(new Date(), plannedFinish, 'short')
        timeTextParagraphs.push({text: timer, variant: 'h5'})
        timeTextParagraphs.push({text: 'to go', variant: 'body2'})
      }

      if (isLate || isEarly) {
        timeTextParagraphs.push({text: offScheduleText, variant: 'body2'})
      }

    } else {
      const timer = getFormattedDurationText(new Date(activity.started_at), new Date(), 'short')
      timeTextParagraphs.push({text: timer, variant: 'h5'})
      timeTextParagraphs.push({text: 'and counting', variant: 'body2'})
    }

  }


  return (
    <>
      <Box width='3px' sx={{marginRight: '-3px', backgroundColor: theme.palette.grey['400']}} />
    <Grid 
      container
      xs={3} 
      display='flex' 
      align='center' 
      justifyContent='center' 
      direction='column'
    >
      {badges.map((b, i) => <StatusBadge key={i} text={b.text} status={b.status} />)}
      {timeTextParagraphs.map((p, i) => <Typography key={i} variant={p.variant}>{p.text}</Typography>)}
    </Grid>
    </>
  )

}

const getStatusColor = (status, theme) => {
  let color = theme.palette.grey['500']

  if (status) {
    const {
      isPlanned,
      isDelay,
      startedLate,
      startedEarly,
      isLate,
      isEarly,
      staleSince, 
    } = status 

    if (isDelay) {
      color = theme.palette.warning.main
    } else if (staleSince || !isPlanned) {
      color = theme.palette.red.main
    } else if (isLate || isEarly || startedLate || startedEarly) {
      color = theme.palette.warning.main
    } else {
      color = theme.palette.success.main
    }
  }
  return color
}

const PlantActivity = (props) => {
  const {activity, currentActivityStatus} = props

  const theme = useTheme()


  const isFutureActivity = !currentActivityStatus

  let status = currentActivityStatus ? {...currentActivityStatus} : null

  if (status) {
    delete status.startedLate
    delete status.startedEarly
  }

  const borderColor = getStatusColor(status, theme)
  const borderFormat = `5px solid ${borderColor}`

  if (!activity && isFutureActivity) {
    return (
      <Card raised={true} sx={{padding: '10px'}}>
        <Typography>No future activities planned</Typography>
      </Card>
    )
  }

  return (
    <Card raised={true} sx={{borderTop: borderFormat}}>
      <Grid container spacing={2} direction='row'>
        <ActivityDetailsSection {...props}/>
        <ActivityTimecountSection {...props}/>
      </Grid>
    </Card>
  )
}

const PlantSummary = ({plant, company}) => {
  const nextActivity = plant?.planned_plant_activities[1]
  const currentActivity = plant?.actual_plant_activities[0]
  const currentPlannedActivity = plant?.planned_plant_activities[0]
  const currentActivityStatus = getActivityStatus(currentActivity, currentPlannedActivity)

  return (
    <Grid item tvScreen={4} xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5'>{plant?.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Now</Typography>
            <PlantActivity 
              activity={currentActivity} 
              currentActivityStatus={currentActivityStatus}
              company={company}
              currentPlannedActivity={currentPlannedActivity}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Next</Typography>
            <PlantActivity 
              activity={nextActivity} 
              activityType='next'
              company={company}
            />
          </Grid>
        </Grid>
    </Grid>
  )
}


const LivePlantDashboard = (props) => {
  const [zoomLevel, setZoomLevel] = useState(null)
  const navigate = useNavigate();
  const location = useLocation()

  const storedZoomLevel = localStorage.getItem('liveDashboardZoom') || '100'

  useEffect(() => {
    if (zoomLevel) {
      document.body.style.zoom = `${zoomLevel}%`;
    } else if (storedZoomLevel){
      document.body.style.zoom = `${storedZoomLevel}%`;
    }
  }, [storedZoomLevel, zoomLevel]);


  const increaseZoomLevel = () => {
    const oldZoom = zoomLevel || storedZoomLevel/1 || 100
    const newZoom = oldZoom + 5
    setZoomLevel(newZoom)
    localStorage.setItem('liveDashboardZoom', newZoom)
  }

  const decreaseZoomLevel = () => {
    const oldZoom = zoomLevel || storedZoomLevel/1 || 100
    const newZoom = oldZoom !== 5 ? zoomLevel - 5 : 5
    setZoomLevel(newZoom)
    localStorage.setItem('liveDashboardZoom', newZoom)
  }

  let {projectId} = useParams()
  const {useGetLivePlantDashboardQuery} = ReportsApi

  const timezoneOffset = new Date().getTimezoneOffset()
  const {data: liveDashboardData, isFetching: isLoading, error}= useGetLivePlantDashboardQuery({projectId, timezoneOffset}, {pollingInterval: 60000})
  const authError = error && error.originalStatus === 401 

  let lastPollText = format(new Date(), 'HH:mm ccc dd MMM')

  if (authError){
    navigate('/login', {replace: true, state: {from: location}})
  }

  const company = liveDashboardData?.company

  const renderPlants = (plants) => {
    if (plants) {
      const sortedPlants = sortByParam(plants, 'name', 'asc')
      return sortedPlants.map((plant) => {
        if (plant.actual_plant_activities.length > 0) {
          return (
            <PlantSummary key={plant.id} plant={plant} company={company}/>
          )
        } else return null
      })
    } else return null
  }

  return(
    <Layout
      title='Live dashboard'
      isLoading={isLoading}
      company={company}
      maxWidth='false'
      productType='lnb'
    >
      {!isLoading && !authError &&
      <Box>
        <Typography variant='body2'>Last updated: {lastPollText}</Typography>
        <Stack spacing={2} direction='row'>
          <Button margin='10px' variant='outlined' size='large' onClick={increaseZoomLevel} >+</Button>
          <Button variant='outlined' size='large' onClick={decreaseZoomLevel} >-</Button>
        </Stack>
        <Grid container spacing={2}>
          {renderPlants(liveDashboardData?.plants)}
        </Grid>
      </Box>
      }
    </Layout>
  )
}


export default LivePlantDashboard

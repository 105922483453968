import {startOfDay} from 'date-fns'
import {sortByParam, parseDate} from '_services'

const getAccessDetails = (requirements, completion_marks) => {
  const mandatoryRequirements = requirements.filter((r) => r.frequency_type === 0)
  let failures = []

  mandatoryRequirements.forEach((r) => {
    r.completion_steps.forEach((step) => {
      const isOptional = step.optional
      if (isOptional) return
      const stepResponses = completion_marks
        .filter((m) => m.completion_step_id === step.id)
      if (stepResponses.length === 0) return failures.push({requirement: r.title, message: 'is missing'})
      const stepResponse = sortByParam(stepResponses, 'created_at', 'desc')[0]
      const successDefinitions = step.question_params.success_definitions
      if (!successDefinitions) return
      successDefinitions.forEach((d) => {
        const isCurrentDateRelated = d.compare_with === "current date"
        const compareValue = isCurrentDateRelated ? startOfDay(new Date()) : d.option_value
        const responseValue = isCurrentDateRelated ? parseDate(stepResponse.options.answer) : stepResponse.options.answer
        let isDefSatisfied = false
        let msg = ''

        switch (d.option_type) {
          case 'when response is less than':
            isDefSatisfied = responseValue < compareValue
            msg = isCurrentDateRelated ? `expired on  ${stepResponse.options.answer}` : `is less than ${compareValue}`
            break
          case 'when response is greater than':
            isDefSatisfied = responseValue > compareValue
            msg = isCurrentDateRelated ? `isn't active until  ${stepResponse.options.answer}` : `is more than ${compareValue}`
            break
          case 'when response is equal to':
            isDefSatisfied = responseValue === compareValue
            msg = 'is equal to'
            break
          default:
            break
        }

        if ((isDefSatisfied && d.result_type === 'fail')) {
          failures.push({requirement: r.title, message: msg})
        }
      })
    })
  })

  const response = failures.length > 0 ? {access: 'denied', failures} : {access: 'granted'}

  return response
}

export default getAccessDetails

import React, {useMemo, createContext, useState, useContext} from 'react';

const TokenContext = createContext();

const useToken = () => {
  return useContext(TokenContext);
}

const TokenProvider = (props) => {
  const [token, setToken] = useState(null);
  const value = useMemo(() => [token, setToken], [token]);
  return <TokenContext.Provider value={value} {...props}/>;
}

export {TokenProvider, useToken}

import {format} from 'date-fns'


const testDateFormat = (dateString, formatString) => {
  try{
    const parsedDate = new Date(dateString)
    const parsedDateString = format(parsedDate, formatString)
    return parsedDateString === dateString
  } catch {
    return false
  }
}

const parseDate = (dateString) => {
  const prioritisedDateFormats = ['YYYY/MM/dd', 'dd/MM/YYYY', 'dd/MM/YY', 'd/M/YY']

  prioritisedDateFormats.forEach((formatString) => {
    if (testDateFormat(dateString, formatString)) {
      return format(dateString, formatString)
    }
  })

  return dateString
}


export default parseDate
